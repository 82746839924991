/**
 * Duck for quotes.
 *
 * @module ducks/quotes
 */

/**
 * The type of actions to describe that the quotes have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const QUOTES_SET = 'QUOTES_SET';
const QUOTE_SET = 'QUOTE_SET';
const QUOTE_CREATE = 'QUOTE_CREATE';
const QUOTE_EDIT = 'QUOTE_EDIT';
const QUOTE_DELETE = 'QUOTE_DELETE';
const QUOTES_SORT_TABLE = 'QUOTES_SORT_TABLE';
const QUOTES_FILTER_TABLE = 'QUOTES_FILTER_TABLE';
const QUOTES_PAGINATION_TABLE = 'QUOTES_PAGINATION_TABLE';

/**
  *  Creates an action to set the quotes to the given quotes.
  *
  * @param {module:types/quotes~Quote[]} quotes - The quotes.
  * @returns {object} - The action.
  * @static
  */

const setQuotes = (quotes) => {
  return {
    quotes,
    type: QUOTES_SET,
  };
};

const createQuote = (quote) => {
  return {
    quote,
    type: QUOTE_CREATE,
  };
};

const updateQuote = (quote) => {
  return {
    quote,
    type: QUOTE_EDIT,
  };
};

const deleteQuote = (id) => {
  return {
    id,
    type: QUOTE_DELETE,
  };
};

const setQuote = (quote) => {
  return {
    quote,
    type: QUOTE_SET,
  };
};

const setQuotesSortTable = (sortModel) => {
  return {
    sortModel,
    type: QUOTES_SORT_TABLE,
  };
};

const setQuotesFilterTable = (filterModel) => {
  return {
    filterModel,
    type: QUOTES_FILTER_TABLE,
  };
};

const setQuotesPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: QUOTES_PAGINATION_TABLE,
  };
};

/**
  * Quotes state.
  *
  * @typedef {object} State
  * @property {module:types/quotes~Quote[]} quotes - The different quotes.
  * @property {module:types/quotes~Quote} quoteType - The current quote.
  * @property {module:types/quotes~SortModel} sortModel - The sort model for the quotes table
  * @property {module:types/quotes~FilterModel} filterModel - The filter model for the quotes table
  * @property {module:types/quotes~PaginationModel} paginationModel - The pagination model for the quotes table
  */

/**
   * The initial quotes state.
   *
   * @type {module:ducks/quotes~State}
   * @static
   */
const initialState = {
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  paginationModel: { page: 0 },
  quote: undefined,
  quotes: [],
  sortModel: [ { field: 'instructor', sort: 'asc' } ],
};

/**
   * Applies the given action to the given state and returns the new state.
   *
   * @param {module:ducks/quotes~State} state - The previous state.
   * @param {object} action - The action.
   * @returns {module:ducks/quotes~State} - The new state.
   * @static
   */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case QUOTES_SET:
    return {
      ...state,
      quote: undefined,
      quotes: action.quotes,
    };
  case QUOTE_SET:
    return {
      ...state,
      quote: action.quote,
    };
  case QUOTE_CREATE:
    return {
      ...state,
      quote: action.quote,
    };
  case QUOTE_EDIT:
    return {
      ...state,
      quote: action.quote,
    };
  case QUOTE_DELETE:
    return {
      ...state,
      quotes: state.quotes.filter((quote) => {
        return action.id !== quote.id;
      }),
    };
  case QUOTES_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case QUOTES_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case QUOTES_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  createQuote,
  deleteQuote,
  setQuote,
  setQuotes,
  setQuotesFilterTable,
  setQuotesPaginationTable,
  setQuotesSortTable,
  updateQuote,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
