import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import DataTable from 'components/common/table/data-table';
import { actions as requestsActions } from 'ducks/requests';
import { actions as roomsActions } from 'ducks/rooms';
import * as roomsMethods from 'resources/rooms/index';
import * as sitesMethods from 'resources/sites/index';
import * as toasts from 'toasts';

const RoomList = () => {

  const initialState = {
    filter: { filterModel: {
      items: [],
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    } },
    pagination: { page: 0 },
    sorting: [ { field: 'siteName', sort: 'asc' } ],
  };

  const navigate = useNavigate();

  const sortModel = useSelector((store) => {
    return store?.rooms?.sortModel || initialState.sorting;
  }, shallowEqual);

  const filterModel = useSelector((store) => {
    return store?.rooms?.filterModel || initialState.filter.filterModel;
  }, shallowEqual);

  const paginationModel = useSelector((store) => {
    return store?.rooms?.paginationModel || initialState.pagination;
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestsActions.request(roomsMethods.getRooms, null, {
      onFailure: (error) => {
        const message = 'Rooms could not be loaded.';
        if ('UnauthorizedError' === error.name) {
          toasts.info(`${ message } Are you signed in?`);
          navigate('/sign-in', { state: { from: '/rooms' } });
        } else {
          toasts.info(message);
        }
      },
      onSuccess: (roomsResult) => {
        dispatch(requestsActions.request(sitesMethods.getSites, null, {
          onFailure: (error) => {
            const message = 'Site names could not be loaded.';
            if ('UnauthorizedError' === error.name) {
              toasts.info(`${ message } Are you signed in?`);
              navigate('/sign-in', { state: { from: '/rooms' } });
            } else {
              toasts.info(message);
              dispatch(roomsActions.setRooms(roomsResult, []));
            }
          },
          onSuccess: (sitesResult) => {
            dispatch(roomsActions.setRooms(roomsResult, sitesResult));
          },
        }));
      },
    }));
  }, [ dispatch, navigate ]);

  const rooms = useSelector((store) => {
    return store.rooms.rooms || [];
  }, shallowEqual);

  const columns = [
    {
      field: 'edit_delete',
      filterable: false,
      headerName: 'Actions',
      renderCell: (cellValues) => {
        return (
          <>
            <div style={ { marginRight: '15px' } }>
              <Button
                color='danger'
                component={ Link }
                size='smaller'
                to={ `/rooms/edit/${ cellValues.row.id }` }
                variant='outlined'
              >
                Edit
              </Button>
            </div>
          </>
        );
      },
      sortable: false,
      width: 100,
    },
    {
      field: 'id',
      filterable: false,
      headerName: 'Room Id',
      minWidth: 180,
      sortable: false,
      type: 'string',
    },
    { field: 'siteName', headerName: 'Site', minWidth: 180, type: 'string' },
    { field: 'name', headerName: 'Name', minWidth: 240, type: 'string' },
    { field: 'hidden', headerName: 'Hidden', type: 'boolean', width: 100 },
    { field: 'layoutImageUrl', headerName: 'Layout Image Url', minWidth: 950, type: 'string' },
  ];

  const setSortModelToStore = (sortModel) => {
    dispatch(roomsActions.setRoomsSortTable(sortModel));
  };

  const setFilterModelToStore = (filterModel) => {
    dispatch(roomsActions.setRoomsFilterTable(filterModel));
  };

  const setPaginationModelToStore = (paginationModel) => {
    dispatch(roomsActions.setRoomsPaginationTable({ page: paginationModel }));
  };

  return (
    <div>
      <h2>Rooms</h2>
      <div className='ui celled list'>
        <DataTable
          columns={ columns }
          filterModel = { filterModel }
          onFilterListModel = { setFilterModelToStore }
          onPaginationModel = { setPaginationModelToStore }
          onSortListModel = { setSortModelToStore }
          pageSize={ 50 }
          paginationModel = { paginationModel }
          rows={ rooms }
          rowsPerPageOptions={ [ 50 ] }
          sortModel={ sortModel }
        />
      </div>
    </div>
  );
};

export default RoomList;
