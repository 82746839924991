/**
 * Resource method to delete a series.
 *
 * @module resources/series/delete-series
 */
import axios from 'axios';

/**
   * Deletes a series based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/series/delete-series~Id} id - The parameters.
   * @returns {Promise<module:resources/series/delete-series~Result>} - The promise.
   * @static
   */
const deleteSeries = (id) => {
  return axios.request({
    data: id,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'delete',
    url: `/series/${ id.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default deleteSeries;
