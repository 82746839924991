import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import DataTable from 'components/common/table/data-table';
import { actions as requestsActions } from 'ducks/requests';
import { actions as spotTypesActions } from 'ducks/spot-types';
import * as spotTypesMethods from 'resources/spot-types/index';
import * as toasts from 'toasts';

const SpotTypeList = () => {
  const initialState = {
    filter: { filterModel: {
      items: [],
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    } },
    pagination: { page: 0 },
    sorting: [ { field: 'name', sort: 'asc' } ],
  };

  const navigate = useNavigate();

  const sortModel = useSelector((store) => {
    return store?.spotTypes?.sortModel || initialState.sorting;
  }, shallowEqual);

  const filterModel = useSelector((store) => {
    return store?.spotTypes?.filterModel || initialState.filter.filterModel;
  }, shallowEqual);

  const paginationModel = useSelector((store) => {
    return store?.spotTypes?.paginationModel || initialState.pagination;
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestsActions.request(spotTypesMethods.getSpotTypes, null, {
      onFailure: (error) => {
        const message = 'Spot Types could not be loaded.';
        if ('UnauthorizedError' === error.name) {
          toasts.info(`${ message } Are you signed in?`);
          navigate('/sign-in', { state: { from: '/spot-types' } });
        } else {
          toasts.info(message);
        }
      },
      onSuccess: (result) => {
        dispatch(spotTypesActions.setSpotTypes(result));
      },
    }));
  }, [ dispatch, navigate ]);

  const spotTypes = useSelector((store) => {
    return store.spotTypes.spotTypes || [];
  }, shallowEqual);

  const columns = [
    {
      field: 'edit_delete',
      filterable: false,
      headerName: 'Actions',
      renderCell: (cellValues) => {
        return (
          <>
            <div style={ { marginRight: '15px' } }>
              <Button
                color='danger'
                component={ Link }
                size='smaller'
                to={ `/spot-types/edit/${ cellValues.row.id }` }
                variant='outlined'
              >
                Edit
              </Button>
            </div>
          </>
        );
      },
      sortable: false,
      width: 100,
    },
    {
      field: 'id',
      filterable: false,
      headerName: 'ID',
      minWidth: 50,
      sortable: false,
      type: 'string',
    },
    { field: 'name', headerName: 'Name', minWidth: 180, type: 'string' },
    { field: 'iconImageUrl', headerName: 'Icon Image Url', minWidth: 900, type: 'string' },
  ];

  const setSortModelToStore = (sortModel) => {
    dispatch(spotTypesActions.setSpotTypesSortTable(sortModel));
  };

  const setFilterModelToStore = (filterModel) => {
    dispatch(spotTypesActions.setSpotTypesFilterTable(filterModel));
  };

  const setPaginationModelToStore = (paginationModel) => {
    dispatch(spotTypesActions.setSpotTypesPaginationTable({ page: paginationModel }));
  };
  return (
    <div>
      <h2>Spot Types</h2>
      <div className='ui celled list'>
        <DataTable
          columns={ columns }
          filterModel = { filterModel }
          onFilterListModel = { setFilterModelToStore }
          onPaginationModel = { setPaginationModelToStore }
          onSortListModel = { setSortModelToStore }
          pageSize={ 50 }
          paginationModel = { paginationModel }
          rows={ spotTypes }
          rowsPerPageOptions={ [ 50 ] }
          sortModel={ sortModel }
        />
      </div>
    </div>
  );
};

export default SpotTypeList;
