import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    danger: {
      main: '#ff8c00',
    },
    error: {
      main: red.A400,
    },
    neutral: {
      main: '#333333',
    },
    primary: {
      main: '#556cd6',
    },
  },
});

export default theme;
