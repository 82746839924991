/**
 * Resource method to get a spot-type.
 *
 * @module resources/spot-types/get-spot-type
 */
import axios from 'axios';

/**
  * Gets a spot-type based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/spot-types/get-spot-type~Params} params - The parameters.
  * @returns {Promise<module:resources/spot-types/get-spot-type~Result>} - The promise.
  * @static
  */

const getSpotType = (id) => {
  return axios.request({
    method: 'get',
    url: `/spot-types/${ id }`,
  }).then((response) => {
    return response.data;
  });
};

export default getSpotType;
