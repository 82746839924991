/**
 * Resource method to get quotes.
 *
 * @module resources/quotes/get-quote
 */
import axios from 'axios';

/**
  * Creates a quote based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/quotes/get-quote~Params} params - The parameters.
  * @returns {Promise<module:resources/quotes/get-quote~Result>} - The promise.
  * @static
  */

const getQuote = (id) => {
  return axios.request({
    method: 'get',
    url: `/quotes/${ id }`,
  }).then((response) => {
    return response.data;
  });
};

export default getQuote;
