/**
 * Application.
 */
import Box from '@mui/material/Box';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import Content from 'components/content/content';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import Loader from 'components/loader/loader';
import Toasts from 'components/toasts/toasts';
import env from 'config/env';

import './app.scss';

const App = (_props) => {

  const basename = new URL(env.PUBLIC_URL).pathname;
  return (
    <div className='brt-app'>
      <Helmet>
      </Helmet>
      <BrowserRouter basename={ basename }>
        <Box
          sx={
            {
              alignItems: 'stretch',
              bgcolor: 'background.paper',
              borderRadius: '12px',
              boxShadow: 1,
              display: 'flex',
              flexDirection: { md: 'column', xs: 'column' },
              fontWeight: 'bold',
              margin: 5,
              overflow: 'hidden',
              padding: 5,
            }
          }
        >
          <Header />
          <Content />
          <Footer />
          <Loader />
          <Toasts />
        </Box>
      </BrowserRouter>
    </div>
  );
};

App.propTypes = {};

export default App;
