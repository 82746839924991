/**
 * Sign-in page.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
//import clsx from 'clsx';
import Joi from 'joi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { actions as authActions } from 'ducks/auth';
import { actions as requestsActions } from 'ducks/requests';
import * as authMethods from 'resources/auth';

const Auth = (_props) => {

  const [ error, setError ] = useState('');

  // Whether the user is authenticated.
  const userAuthenticated = useSelector((store) => {
    return undefined !== store.auth.accessToken;
  }, shallowEqual);

  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors } } = useForm({
    reValidateMode: 'onChange',
    resolver: joiResolver(Joi.object({
      email: Joi.string().min(1).max(256).required(),
      password: Joi.string().min(1).max(256).required(),
    })),
  });

  const location = useLocation();

  const navigate = useNavigate();

  // The page that the user tried to visit when they got redirected here.
  const from = location.state?.from || '/home';

  // Function that signs in a user based on the given parameters.
  const signIn = (params) => {
    setError('');
    // Sign in.
    dispatch(requestsActions.request(authMethods.signIn, params, {
      navigate,
      onFailure: (error) => {
        switch (error.message) {
        case 'UNAUTHORIZED':
          setError('Email and/or password are invalid.');
          break;
        default:
          setError('Oops! Something went wrong.');
          break;
        }
      },
      onSuccess: (result) => {
        dispatch(authActions.signIn(result.token));
        // Take the user to the page they requested.
        navigate(from, { replace: true });
      },
    }));
  };

  return userAuthenticated ? (<Navigate replace to={ from } />) : (
    <div className='sign-in'>
      <h2 className='hdg hdg-md'>{ 'Sign In' }</h2>
      <Stack className='fields' >
        <TextField
          { ...register('email') }
          InputLabelProps={ { shrink: true } }
          color='primary'
          error={ errors.email? true:false }
          helperText={ errors.email?.message }
          label={ 'Email' }
          margin='normal'
          name='email'
          placeholder={ 'Email' }
          required
          size='small'
          type='email'
          variant = 'outlined'
        />
        <TextField
          { ...register('password') }
          InputLabelProps={ { shrink: true } }
          color='primary'
          error={ errors.password? true:false }
          helperText={ errors.password?.message }
          label={ 'Password' }
          margin='normal'
          name='password'
          placeholder={ 'Password' }
          required
          size='small'
          type='password'
          variant = 'outlined'
        />
      </Stack>
      {
        undefined !== error && (
          <div className='error'>{ error }</div>
        )
      }
      <Box sx={ { mb: 5, mt: 3 } }>

        <Button
          color='primary'
          onClick={ handleSubmit(signIn) }
          variant='contained'
        >
          { 'Sign In' }
        </Button>
      </Box>

    </div> );
};

Auth.propTypes = {
};

export default Auth;
