/**
 * Resource method to get series types.
 *
 * @module resources/series-types/get-series-types
 */
import axios from 'axios';

/**
 * Gets the series types.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/series-types/get-series-types~Result>} - The promise.
 * @static
 */
const getSeriesTypes = () => {
  return axios.request({
    method: 'get',
    url: '/series-types/',
  }).then((response) => {
    return response.data;
  });
};

export default getSeriesTypes;
