/**
 * Series editor.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as requestsActions } from 'ducks/requests';
import { actions as seriesTypesActions } from 'ducks/series-types';
import * as seriesMethods from 'resources/series';
import * as seriesTypesMethods from 'resources/series-types/index';
import * as toasts from 'toasts';

const SeriesEditor = (props) => {

  // The error when saving changes.
  const [ error, setError ] = useState('');

  const [ seriesTypesEligible, setSeriesTypesEligible ] = React.useState(true);

  const dispatch = useDispatch();

  // The ID of the series.
  const seriesId = props?.series?.id || null;

  const isAddMode = !seriesId;

  const seriesTypes = useSelector((store) => {
    return store.seriesTypes.seriesTypes || {};
  }, shallowEqual);

  // The validation schema.
  const schema = Joi.object({
    description: Joi.string().allow(''),
    displayCategory: Joi.string().allow(''),
    displayOrder: Joi.number().allow(''),
    enabled: Joi.bool().required(),
    exclude: Joi.bool(),
    excludeDescription: Joi.string().allow(''),
    name: Joi.string().min(1).required(),
    price: Joi.number(),
    seriesTypeId: Joi.string().required(),
    zingfitDescription: Joi.string().allow(''),
    zingfitName: Joi.string().allow(''),
  });

  const { control, formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: {
      description: '',
      displayCategory: '',
      displayOrder: '',
      enabled: '',
      exclude: '',
      excludeDescription: '',
      name: '',
      price: '',
      seriesTypeId: '',
      zingfitDescription: '',
      zingfitName: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const onFormSubmit = (params) => {
    return isAddMode
      ? createSeries(params)
      : updateSeries(seriesId, params);
  };
  const createSeries = (params) => {
    setError('');

    dispatch(requestsActions.request(seriesMethods.createSeries, {
      series: {
        ...params,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Series could not be created.');
        setError(`Series could not be created: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Series was created.');
        props.onSave?.(result);
      },
    }));
  };

  const updateSeries = (id, params) => {

    setError('');

    dispatch(requestsActions.request(seriesMethods.updateSeries, {
      series: {
        ...params,
        id: id,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Series could not be updated.');
        setError(`Series could not be updated: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Series was updated.');
        props.onSave?.(result);
      },
    }));
  };

  // Fill in the form with values from the series.
  useEffect(() => {
    const values = {};

    if (!isAddMode) {
      values.description = props?.series?.description || '';
      values.displayCategory = props?.series?.displayCategory || '';
      values.displayOrder = props?.series?.displayOrder || '';
      values.enabled = props?.series?.enabled || false;
      values.exclude = props?.series?.exclude || false;
      values.excludeDescription = props?.series?.excludeDescription || '';
      values.name = props?.series?.name || '';
      values.price = props?.series?.price || null;
      values.seriesTypeId = props?.series?.seriesTypeId || '';
      values.zingfitDescription = props?.series?.zingfitDescription || '';
      values.zingfitName = props?.series?.zingfitName || '';
    }
    reset(values);

    dispatch(requestsActions.request(seriesTypesMethods.getSeriesTypes, null, {
      onFailure: () => {
        toasts.info('Series Types could not be loaded.');
      },
      onSuccess: (result) => {
        dispatch(seriesTypesActions.setSeriesTypes(result));
        const elig = result.filter((elem) => {
          return elem.id === props?.series?.seriesTypeId;
        });
        setSeriesTypesEligible(elig[0]?.eligibleForPerks===undefined? false: elig[0].eligibleForPerks);
      },
    }));
  }, [ dispatch, isAddMode, props.series, reset ]);

  return (
    <div className='series editor'>
      <h2 className='hdg hdg-md'>{ props.title }</h2>
      <form autoComplete='off'>
        <Stack className='fields' >
          <TextField
            { ...register('name') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.name? true:false }
            helperText={ errors.name?.message }
            label={ 'Name' }
            margin='normal'
            name='name'
            placeholder={ 'Name' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('zingfitName') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            disabled={ !isAddMode }
            error={ errors.zingfitName? true:false }
            helperText={ errors.zingfitName?.message }
            label={ 'Zingfit Name' }
            margin='normal'
            name='zingfitName'
            placeholder={ 'Zingfit Name' }
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('price') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            disabled={ !isAddMode }
            error={ errors.price? true:false }
            helperText={ errors.price?.message }
            label={ 'Price' }
            margin='normal'
            name='price'
            placeholder={ 'Price' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('description') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.description? true:false }
            helperText={ errors.description?.message }
            label={ 'Description' }
            margin='normal'
            minRows = { 3 }
            multiline
            name='description'
            placeholder={ 'Description' }
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('displayOrder') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.displayOrder? true:false }
            helperText={ errors.displayOrder?.message }
            label={ 'App Display Order' }
            margin='normal'
            name='displayOrder'
            placeholder={ 'App Display Order' }
            size='small'
            variant = 'outlined'
          />
          <FormControl disabled={ !isAddMode }>
            <FormLabel sx={ { mt: 3 } }>Enabled</FormLabel>
            <Controller
              control={ control }

              name='enabled'
              render={
                ({ field }) => {
                  return (
                    <RadioGroup
                      { ...field }
                      onChange={
                        (e) => {
                          field.onChange('true' === e.target.value);
                        }
                      }
                      row
                      value={ field?.value !== undefined ? field.value : '' }
                    >
                      <FormControlLabel
                        control={ <Radio /> }
                        label='Yes'
                        value={ 'true' }
                      />
                      <FormControlLabel
                        control={ <Radio /> }
                        label='No'
                        value={ 'false' }
                      />

                    </RadioGroup>
                  );
                }
              }
              rules={ { required: true } }
            />
          </FormControl>
          <Box sx={ { display: 'flex', height: 120 } }>
            <FormControl disabled={ !isAddMode } sx={ { p: 1, width: 1/3 } }>
              <FormLabel sx={ { mt: 3 } }>
               Perk eligibility
              </FormLabel>
              <Select
                onChange={ (e) => { return setSeriesTypesEligible(e.target.value); } }
                sx={ { height: 40, mt: 2 } }
                value={ seriesTypesEligible!==undefined? seriesTypesEligible: false }
              >
                <MenuItem value={ true }>Eligible</MenuItem>
                <MenuItem value={ false }>Non-eligible</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              disabled={ !isAddMode }
              required
              sx={ { flexGrow: 1, p: 1 } }
            >
              <FormLabel
                htmlFor='seriesTypeId'
                sx={ { ml: 3, mt: 3 } }
              >
               Series Type
              </FormLabel>
              <Controller
                control={ control }
                name='seriesTypeId'
                render={
                  ({ field }) => {
                    return (
                      // TODO: if we want to enable selections, we need to fix how values are
                      // displayed (both here and in eligibility filter above).
                      <Select
                        margin='dense'
                        size='small'
                        { ...field }
                        onChange={
                          (e) => {
                            field.onChange(e.target.value);
                          }
                        }
                        sx={
                          {
                            height: 40,
                            ml: 3,
                            mt: 2,
                          }
                        }
                        value={ field?.value || '' }
                      >
                        {
                          seriesTypes?.filter((seriesType) => {
                            // if eligibleForPerks not set, show seriesType with non-eligible
                            if ('eligibleForPerks' in seriesType) {
                              return seriesType.eligibleForPerks === seriesTypesEligible;
                            }
                            return !seriesTypesEligible;
                          }).sort((a, b) => { return a.name.localeCompare(b.name); }).map((seriesType) => {
                            return (
                              <MenuItem key={ seriesType.id } value={ seriesType.id }>
                                { seriesType.name }
                                { '  (#' }
                                { seriesType.id }
                                { ')' }
                              </MenuItem>
                            );
                          } )
                        }
                      </Select>
                    );
                  }
                }
                seriesTypesEligible = { seriesTypesEligible }
              />
            </FormControl>
          </Box>
          <FormControl>
            <FormLabel sx={ { mt: 1 } }>Exclude</FormLabel>
            <Controller
              control={ control }
              name='exclude'
              render={
                ({ field }) => {
                  return (
                    <RadioGroup
                      { ...field }
                      onChange={
                        (e) => {
                          field.onChange('true' === e.target.value);
                        }
                      }
                      row
                      sx={ { mb: 2 } }
                      value={ field?.value !== undefined ? field.value : '' }
                    >
                      <FormControlLabel
                        control={ <Radio /> }
                        label='Yes'
                        value={ 'true' }
                      />
                      <FormControlLabel
                        control={ <Radio /> }
                        label='No'
                        value={ 'false' }
                      />
                    </RadioGroup>
                  );
                }
              }
              rules={ { required: true } }
            />
          </FormControl>
          <TextField
            { ...register('excludeDescription') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.excludeDescription? true:false }
            helperText={ errors.excludeDescription?.message }
            label={ 'Exclude Description' }
            margin='normal'
            name='excludeDescription'
            placeholder={ 'Exclude Description' }
            size='small'
            variant = 'outlined'
          />

        </Stack>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                props.onCancel?.();
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='primary'
            onClick={ handleSubmit(onFormSubmit) }
            variant='contained'
          >
            { 'Save' }
          </Button>
        </Box>
      </form>
    </div>
  );
};

SeriesEditor.propTypes = {
  // The function ((Series) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func.isRequired,
  // The function ((Series) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func.isRequired,
  // The series
  series: PropTypes.shape({
    description: PropTypes.string,
    displayCategory: PropTypes.string,
    displayOrder: PropTypes.number,
    enabled: PropTypes.bool,
    exclude: PropTypes.bool,
    excludeDescription: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    price: PropTypes.number,
    seriesTypeId: PropTypes.string,
    zingfitDescription: PropTypes.string,
    zingfitName: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default SeriesEditor;
