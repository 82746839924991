/**
 * Errors thrown when a request is sent, but no response is received.
 *
 * @module errors/request-error
 */

class RequestError extends Error {
  constructor(message) {
    super(message || 'REQUEST_ERROR');
    this.name = 'RequestError';
  }
}

export default RequestError;
