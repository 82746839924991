import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions as requestsActions } from 'ducks/requests';
import { actions as roomsActions } from 'ducks/rooms';
import * as roomsMethods from 'resources/rooms/index';
import * as toasts from 'toasts';

import RoomEditor from './room-editor/room-editor';

const RoomEdit = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(roomsMethods.getRoom, id, {
      onFailure: () => {
        toasts.info('Room could not be loaded.');
      },
      onSuccess: (result) => {
        dispatch(roomsActions.setRoom(result));
      },
    }));
  }, [ dispatch, id ]);

  const roomToEdit = useSelector((store) => {
    return store.rooms.room || {};
  }, shallowEqual);

  return (
    <div>
      <h2>Rooms</h2>
      <RoomEditor
        onCancel={
          () => {
            navigate('/rooms');
          }
        }
        onSave={
          () => {
            navigate('/rooms');
          }
        }
        room={ roomToEdit }
        title= { 'Edit Room' }
      />
    </div>
  );
};

export default RoomEdit;
