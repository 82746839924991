/**
 * Resource method to update a series.
 *
 * @module resources/series/update-getSeries
 */
import axios from 'axios';

import { omit } from 'utilities/chisels';

/**
   * Params.
   *
  * @typedef {object} Params
  * @property {string} name
  * @property {string} zingfitName
  * @property {number} price
  * @property {string} description
  * @property {string} zingfitDescription
  * @property {string} appDisplayOrder
  * @property {string} appDisplayCategory
  * @property {boolean} enabled
  * @property {number} seriesTypeId
  * @property {boolean} exclude
  * @property {string} excludeDescription
   */

/**
   * Result.
   *
   * @typedef {object} Result
   */

/**
   * Updates a series based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/series/update-series~Params} params - The parameters.
   * @returns {Promise<module:resources/series/update-series~Result>} - The promise.
   * @static
   */
const updateSeries = (params) => {
  return axios.request({
    data: JSON.stringify({
      ...omit(params.series, [ 'id' ]),
      part: params.part,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: `/series/${ params.series.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default updateSeries;
