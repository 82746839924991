/**
 * Resource method to update a quote.
 *
 * @module resources/quotes/update-quote
 */
import axios from 'axios';

import { omit } from 'utilities/chisels';

/**
   * Params.
   *
   *
   * @typedef {object} Params
   * @property {string} instructor
   * @property {string} quote
   */

/**
   * Result.
   *
   * @typedef {object} Result
   */

/**
   * Updates a quote based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/quotes/update-quote~Params} params - The parameters.
   * @returns {Promise<module:resources/quotes/update-quote~Result>} - The promise.
   * @static
   */
const updateQuote = (params) => {
  return axios.request({
    data: JSON.stringify({
      ...omit(params.quote, [ 'id' ]),
      part: params.part,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: `/quotes/${ params.quote.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default updateQuote;
