import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import DataTable from 'components/common/table/data-table';
import { actions as quotesActions } from 'ducks/quotes';
import { actions as requestsActions } from 'ducks/requests';
import * as quotesMethods from 'resources/quotes/index';
import * as toasts from 'toasts';

const QuoteList = () => {

  const initialState = {
    filter: { filterModel: {
      items: [],
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    } },
    pagination: { page: 0 },
    sorting: [ { field: 'instructor', sort: 'asc' } ],
  };

  const navigate = useNavigate();

  const sortModel = useSelector((store) => {
    return store?.quotes?.sortModel || initialState.sorting;
  }, shallowEqual);

  const filterModel = useSelector((store) => {
    return store?.quotes?.filterModel || initialState.filter.filterModel;
  }, shallowEqual);

  const paginationModel = useSelector((store) => {
    return store?.quotes?.paginationModel || initialState.pagination;
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestsActions.request(quotesMethods.getQuotes, null, {
      onFailure: (error) => {
        const message = 'Quotes could not be loaded.';
        if ('UnauthorizedError' === error.name) {
          toasts.info(`${ message } Are you signed in?`);
          navigate('/sign-in', { state: { from: '/quotes' } });
        } else {
          toasts.info(message);
        }
      },
      onSuccess: (result) => {
        dispatch(quotesActions.setQuotes(result));
      },
    }));
  }, [ dispatch, navigate ]);

  const quotes = useSelector((store) => {
    return store.quotes.quotes || [];
  }, shallowEqual);

  const renderCreate = () => {
    return (
      <div style={ { paddingBottom: '15px', textAlign: 'left' } }>
        <Button
          color='primary'
          component={ Link }
          to='/quotes/new'
          variant='contained'
        >
          Create Quote
        </Button>
      </div>
    );
  };

  const columns = [
    {
      field: 'edit_delete',
      filterable: false,
      headerName: 'Actions',
      renderCell: (cellValues) => {
        return (
          <>
            <div style={ { marginRight: '15px' } }>
              <Button
                color='danger'
                component={ Link }
                size='smaller'
                to={ `/quotes/edit/${ cellValues.row.id }` }
                variant='outlined'
              >
                Edit
              </Button>
            </div>
            <Button
              color='error'
              component={ Link }
              size='smaller'
              to={ `/quotes/delete/${ cellValues.row.id }` }
              variant='outlined'
            >
                Delete
            </Button>
          </>
        );
      },
      sortable: false,
      width: 200,
    },
    { field: 'instructor', headerName: 'Instructor', minWidth: 250, type: 'string' },
    { field: 'quote', headerName: 'Quote', minWidth: 700, type: 'string' },
  ];

  const setSortModelToStore = (sortModel) => {
    dispatch(quotesActions.setQuotesSortTable(sortModel));
  };

  const setFilterModelToStore = (filterModel) => {
    dispatch(quotesActions.setQuotesFilterTable(filterModel));
  };

  const setPaginationModelToStore = (paginationModel) => {
    dispatch(quotesActions.setQuotesPaginationTable({ page: paginationModel }));
  };

  return (
    <div>
      <h2>Quotes</h2>
      { renderCreate() }
      <div className='ui celled list'>
        <DataTable
          columns={ columns }
          filterModel = { filterModel }
          onFilterListModel = { setFilterModelToStore }
          onPaginationModel = { setPaginationModelToStore }
          onSortListModel = { setSortModelToStore }
          pageSize={ 50 }
          paginationModel = { paginationModel }
          rows={ quotes }
          rowsPerPageOptions={ [ 50 ] }
          sortModel={ sortModel }
        />
      </div>
    </div>
  );
};

export default QuoteList;
