/**
 * Resource method to create a spot-type.
 *
 * @module resources/spot-types/create-spot-type
 */
import axios from 'axios';

/**
  * Params.
  *
  *
  * @typedef {object} Params
  * @property {string} name
  * @property {string} iconImageUrl
  */

/**
  * Result.
  *
  * @typedef {object} Result
  * @property {string} id - The ID of the spot-type.
  */

/**
  * Creates a spot-type based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/spot-types/create-spot-type~Params} params - The parameters.
  * @returns {Promise<module:resources/spot-types/create-spot-type~Result>} - The promise.
  * @static
  */
const createSpotType = (params) => {
  return axios.request({
    data: JSON.stringify(params.spotType),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/spot-types',
  }).then((response) => {
    return response.data;
  });
};

export default createSpotType;
