/**
 * Resource method to delete a series type.
 *
 * @module resources/series-types/delete-series-type
 */
import axios from 'axios';

/**
   * Deletes a series-type based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/series-types/delete-series-type~Id} id - The parameters.
   * @returns {Promise<module:resources/series-types/delete-series-type~Result>} - The promise.
   * @static
   */
const deleteSeriesType = (id) => {
  return axios.request({
    data: id,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'delete',
    url: `/series-types/${ id.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default deleteSeriesType;
