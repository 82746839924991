/**
 * Perk editor.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actions as requestsActions } from 'ducks/requests';
import * as perksMethods from 'resources/perks';
import * as toasts from 'toasts';

const PerkEditor = (props) => {

  // The error when saving changes.
  const [ error, setError ] = useState('');

  const dispatch = useDispatch();

  // The ID of the perk.
  const perkId = props?.perk?.id || null;

  const isAddMode = !perkId;

  // The validation schema.
  const schema = Joi.object({
    brandImageUrl: Joi.string().uri().required(),
    brandName: Joi.string().min(1).required(),
    description: Joi.string().min(1).required(),
    displayOrder: Joi.number().allow(''),
    enabled: Joi.bool().required(),
    redemptionCode: Joi.string().min(1).allow(''),
    redemptionQrCodeUrl: Joi.string().uri().allow(''),
    redemptionUrl: Joi.string().uri().allow(''),
    shortDescription: Joi.string().min(1).required(),
    title: Joi.string().min(1).required(),
  });

  const { control, formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: {
      brandImageUrl: '',
      brandName: '',
      description: '',
      displayOrder: '',
      enabled: '',
      redemptionCode: '',
      redemptionQrCodeUrl: '',
      redemptionUrl: '',
      shortDescription: '',
      title: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const onFormSubmit = (params) => {
    return isAddMode
      ? createPerk(params)
      : updatePerk(perkId, params);
  };
  const createPerk = (params) => {
    setError('');

    dispatch(requestsActions.request(perksMethods.createPerk, {
      perk: {
        ...params,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Perk could not be created.');
        setError(`Perk could not be created: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Perk was created.');
        props.onSave?.(result);
      },
    }));
  };

  const updatePerk = (id, params) => {

    setError('');

    dispatch(requestsActions.request(perksMethods.updatePerk, {
      perk: {
        ...params,
        id: id,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Perk could not be updated.');
        setError(`Perk could not be updated: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Perk was updated.');
        props.onSave?.(result);
      },
    }));
  };

  // Fill in the form with values from the perk.
  useEffect(() => {
    const values = {};

    if (!isAddMode) {
      values.displayOrder = props?.perk?.displayOrder || '';
      values.brandImageUrl = props?.perk?.brandImageUrl || '';
      values.brandName = props?.perk?.brandName || null;
      values.description = props?.perk?.description || '';
      values.redemptionCode = props?.perk?.redemptionCode || '';
      values.redemptionQrCodeUrl = props?.perk?.redemptionQrCodeUrl || '';
      values.redemptionUrl = props?.perk?.redemptionUrl || '';
      values.shortDescription = props?.perk?.shortDescription || '';
      values.title = props?.perk?.title || null;
      values.enabled = props?.perk?.enabled || false;
    }
    reset(values);

  }, [ props.perk, reset, isAddMode ]);

  return (
    <div className='perk editor'>
      <h2 className='hdg hdg-md'>{ props.title }</h2>
      <form autoComplete='off'>
        { /* <h2 className='title'>{ props.title }</h2> */ }
        <Stack className='fields' >
          <TextField
            { ...register('title') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.title? true:false }
            helperText={ errors.title?.message }
            label={ 'Title' }
            margin='normal'
            name='title'
            placeholder={ 'Title' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('brandName') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.brandName? true:false }
            helperText={ errors.brandName?.message }
            label={ 'Brand Name' }
            margin='normal'
            name='brandName'
            placeholder={ 'Brand Name' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('brandImageUrl') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.brandImageUrl? true:false }
            helperText={ errors.brandImageUrl?.message }
            label={ 'Brand Image url' }
            margin='normal'
            name='brandImageUrl'
            placeholder={ 'Brand image url' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('shortDescription') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.shortDescription? true:false }
            helperText={ errors.shortDescription?.message }
            label={ 'Short description' }
            margin='normal'
            name='shortDescription'
            placeholder={ 'Short description' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('description') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.description? true:false }
            helperText={ errors.description?.message }
            label={ 'Description' }
            margin='normal'
            minRows = { 3 }
            multiline
            name='description'
            placeholder={ 'Description' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('redemptionCode') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.redemptionCode? true:false }
            helperText={ errors.redemptionCode?.message }
            label={ 'Redemption code' }
            margin='normal'
            name='redemptionCode'
            placeholder={ 'Redemption code' }
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('redemptionUrl') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.redemptionUrl? true:false }
            helperText={ errors.redemptionUrl?.message }
            label={ 'Redemption url' }
            margin='normal'
            name='redemptionUrl'
            placeholder={ 'Redemption url' }
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('redemptionQrCodeUrl') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.redemptionQrCodeUrl? true:false }
            helperText={ errors.redemptionQrCodeUrl?.message }
            label={ 'Redemption QR code url' }
            margin='normal'
            name='redemptionQrCodeUrl'
            placeholder={ 'Redemption QR code url' }
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('displayOrder') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.displayOrder? true:false }
            helperText={ errors.displayOrder?.message }
            label={ 'App display order' }
            margin='normal'
            name='displayOrder'
            placeholder={ 'App display order' }
            size='small'
            variant = 'outlined'
          />
          <FormControl>
            <FormLabel>Enabled</FormLabel>
            <Controller
              control={ control }
              name='enabled'
              render={
                ({ field }) => {
                  return (
                    <RadioGroup
                      { ...field }
                      onChange={
                        (e) => {
                          field.onChange('true' === e.target.value);
                        }
                      }
                      row
                      value={ field?.value !== undefined ? field.value : '' }
                    >
                      <FormControlLabel
                        control={ <Radio /> }
                        label='Yes'
                        value={ 'true' }
                      />
                      <FormControlLabel
                        control={ <Radio /> }
                        label='No'
                        value={ 'false' }
                      />

                    </RadioGroup>
                  );
                }
              }
              rules={ { required: true } }
            />
          </FormControl>

        </Stack>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                props.onCancel?.();
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='primary'
            onClick={ handleSubmit(onFormSubmit) }
            variant='contained'
          >
            { 'Save' }
          </Button>
        </Box>
      </form>
    </div>
  );
};

PerkEditor.propTypes = {
  // The function ((Perk) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func.isRequired,
  // The function ((Perk) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func.isRequired,
  // The perk
  perk: PropTypes.shape({
    brandImageUrl: PropTypes.string,
    brandName: PropTypes.string,
    description: PropTypes.string,
    displayOrder: PropTypes.number,
    enabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    redemptionCode: PropTypes.string,
    redemptionQrCodeUrl: PropTypes.string,
    redemptionUrl: PropTypes.string,
    shortDescription: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default PerkEditor;
