/**
 * Resource method to get a perk.
 *
 * @module resources/perks/get-perk
 */
import axios from 'axios';

/**
  * Gets a perk based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/perks/get-perk~Params} params - The parameters.
  * @returns {Promise<module:resources/perks/get-perk~Result>} - The promise.
  * @static
  */

const getPerk = (id) => {
  return axios.request({
    method: 'get',
    url: `/perks/${ id }`,
  }).then((response) => {
    return response.data;
  });
};

export default getPerk;
