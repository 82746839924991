import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SimpleTable from 'components/common/table/simple-table';
import { actions as requestsActions } from 'ducks/requests';
import { actions as roomsActions } from 'ducks/rooms';
import * as roomsMethods from 'resources/rooms/index';
import * as toasts from 'toasts';

const RoomDelete = () => {

  // The error when deleting a room.
  const [ error, setError ] = useState('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(roomsMethods.getRoom, id, {
      onFailure: () => {
        toasts.info('Room not found.');
      },
      onSuccess: (result) => {
        dispatch(roomsActions.setRoom(result));
      },
    }));
  }, [ dispatch, id ]);

  const roomToDelete = useSelector((store) => {
    return store.rooms.room || {};
  }, shallowEqual);

  const columns = [
    {
      field: 'field',
      filterable: false,
      flex: 1,
    },
    {
      field: 'fieldValue',
      filterable: false,
      flex: 6,
      renderCell: (cellValues) => {
        return (
          <p style={
            { fontWeight: 'bolder',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word' }
          }
          >
            { cellValues.row.fieldValue }
          </p>);
      },
    },
  ];

  const deleteCurrentRoom = () => {

    setError('');

    dispatch(requestsActions.request(roomsMethods.deleteRoom, { id: id }, {
      onFailure: (_error) => {
        toasts.info('Room could not be deleted.');
        setError(`Room could not be deleted: ${ _error.name }`);
      },
      onSuccess: () => {
        dispatch(roomsActions.deleteRoom(id));
        toasts.info('Room was deleted.');
        navigate('/rooms');
      },
    }));
  };

  return (
    <div className='room editor'>
      <h2 className='hdg hdg-md'>
        { 'Delete Room?' }
      </h2>
      <SimpleTable
        columns={ columns }
        rows={
          [
            {
              field: 'Name :',
              fieldValue: roomToDelete.name,
              width: 250,
            },
            {
              field: 'Layout Image Url :',
              fieldValue: roomToDelete.layoutImageUrl,
              width: 250,
            },
            {
              field: 'Site Id :',
              fieldValue: roomToDelete.siteId,
              width: 250,
            },
            {
              field: 'Hidden :',
              fieldValue: roomToDelete.hidden ? 'yes' : 'no',
              width: 250,
            },
          ]
        }
      />
      <div className='buttons'>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                navigate('/rooms');
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='error'
            onClick={ deleteCurrentRoom }
            variant='contained'
          >
            { 'Delete Room' }
          </Button>
        </Box>
      </div>

    </div>
  );
};

export default RoomDelete;
