/**
 * Entry point.
 */
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'components/app/app';
import webVitals from 'helpers/web-vitals';
import { persistor, store } from 'store';

import theme from './theme';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'styles/styles.scss';

const element = (
  <React.StrictMode>
    <Provider store={ store }>
      { /* Do not render the app, until the persisted state has been loaded. */ }
      <PersistGate loading={ null } persistor={ persistor }>
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
const container = document.getElementById('root');
ReactDOM.render(element, container);

// Log web vitals.
// eslint-disable-next-line no-console
webVitals.configure(console.log);
