import React from 'react';
import { useNavigate } from 'react-router-dom';

import SeriesTypeEditor from './series-type-editor/series-type-editor';

const SeriesTypeCreate = () => {

  const navigate = useNavigate();

  return (
    <div>
      <h2>Series Type</h2>
      <SeriesTypeEditor
        onCancel={
          () => {
            navigate('/series-types');
          }
        }
        onSave={
          () => {
            navigate('/series-types');
          }
        }
        title='Create Series Type '
      />
    </div>
  );
};

export default SeriesTypeCreate;
