/**
 * Resource method to create a perk.
 *
 * @module resources/perks/create-perk
 */
import axios from 'axios';

/**
  * Params.
  *
  *
  * @typedef {object} Params
  * @property {string} brandName
  * @property {string} brandImageUrl
  * @property {string} title
  * @property {string} shortDescription
  * @property {string} description
  * @property {string} redemptionUrl
  * @property {string} redemptionQrCodeUrl
  * @property {string} redemptionCode
  * @property {boolean} enabled
  * @property {string} displayOrder
  */

/**
  * Result.
  *
  * @typedef {object} Result
  * @property {string} id - The ID of the perk.
  */

/**
  * Creates a perk based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/perks/create-perk~Params} params - The parameters.
  * @returns {Promise<module:resources/perks/create-perk~Result>} - The promise.
  * @static
  */
const createPerk = (params) => {
  return axios.request({
    data: JSON.stringify(params.perk),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/perks',
  }).then((response) => {
    return response.data;
  });
};

export default createPerk;
