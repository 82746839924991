import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions as requestsActions } from 'ducks/requests';
import { actions as seriesTypesActions } from 'ducks/series-types';
import * as seriesTypesMethods from 'resources/series-types/index';
import * as toasts from 'toasts';

import SeriesTypeEditor from './series-type-editor/series-type-editor';

const SeriesTypeEdit = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(seriesTypesMethods.getSeriesType, id, {
      onFailure: () => {
        toasts.info('Series Type could not be loaded.');
      },
      onSuccess: (result) => {
        dispatch(seriesTypesActions.setSeriesType(result));
      },
    }));
  }, [ dispatch, id ]);

  const seriesTypeToEdit = useSelector((store) => {
    return store.seriesTypes.seriesType || {};
  }, shallowEqual);

  return (
    <div>
      <h2>Series Types</h2>
      <SeriesTypeEditor
        onCancel={
          () => {
            navigate('/series-types');
          }
        }
        onSave={
          () => {
            navigate('/series-types');
          }
        }
        seriesType={ seriesTypeToEdit }
        title= { 'Edit Series Type' }
      />
    </div>
  );
};

export default SeriesTypeEdit;
