import React from 'react';
import { useNavigate } from 'react-router-dom';

import SpotTypeEditor from './spot-type-editor/spot-type-editor';
import './spot-type-create.scss';

const SpotTypeCreate = () => {

  const navigate = useNavigate();

  return (
    <div>
      <h2>Spot Types</h2>
      <SpotTypeEditor
        onCancel={
          () => {
            navigate('/spot-types');
          }
        }
        onSave={
          () => {
            navigate('/spot-types');
          }
        }
        title='Create Spot Type'
      />
    </div>
  );
};

export default SpotTypeCreate;
