/**
 * Errors thrown when a response contains a 401 (UNAUTHORIZED) status code.
 *
 * @module errors/unauthorized-error
 */
import ResponseError from './response-error';

class UnauthorizedError extends ResponseError {
  constructor(message) {
    super(message || 'UNAUTHORIZED');
    this.name = 'UnauthorizedError';
  }
}

export default UnauthorizedError;
