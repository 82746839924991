/**
 * Resource method to get a series.
 *
 * @module resources/series/get-series
 */
import axios from 'axios';

/**
  * Gets a series based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/series/get-series~Params} params - The parameters.
  * @returns {Promise<module:resources/series/get-series~Result>} - The promise.
  * @static
  */

const getSeries = (id) => {
  return axios.request({
    method: 'get',
    url: `/series/${ id }`,
  }).then((response) => {
    return response.data;
  });
};

export default getSeries;
