/**
 * Room editor.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actions as requestsActions } from 'ducks/requests';
import * as roomsMethods from 'resources/rooms';
import * as toasts from 'toasts';

const RoomEditor = (props) => {

  // The error when saving changes.
  const [ error, setError ] = useState('');

  const dispatch = useDispatch();

  // The ID of the room.
  const roomId = props?.room?.id || null;

  // The validation schema.
  const schema = Joi.object({
    hidden: Joi.bool().required(),
    layoutImageUrl: Joi.string().uri().allow(''),
    name: Joi.string(),
    siteId: Joi.string(),
  });

  const { control, formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: {
      hidden: '',
      layoutImageUrl: '',
      name: '',
      siteId: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const onFormSubmit = (params) => {
    return updateRoom(roomId, params);
  };

  const updateRoom = (id, params) => {

    setError('');

    dispatch(requestsActions.request(roomsMethods.updateRoom, {
      room: {
        ...params,
        id: id,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Room could not be updated.');
        setError(`Room could not be updated: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Room was updated.');
        props.onSave?.(result);
      },
    }));
  };

  // Fill in the form with values from the room.
  useEffect(() => {
    const values = {};
    values.name = props?.room?.name || '';
    values.layoutImageUrl = props?.room?.layoutImageUrl || '';
    values.siteId = props?.room?.siteId || null;
    values.hidden = props?.room?.hidden || false;
    reset(values);

  }, [ props.room, reset ]);

  return (
    <div className='room editor'>
      <h2 className='hdg hdg-md'>{ props.title }</h2>
      <form autoComplete='off'>
        <Stack className='fields' >
          <TextField
            { ...register('name') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            disabled={ true }
            error={ errors.name? true:false }
            helperText={ errors.name?.message }
            label={ 'Name' }
            margin='normal'
            name='name'
            placeholder={ 'Name' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('layoutImageUrl') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            disabled={ true }
            error={ errors.layoutImageUrl? true:false }
            helperText={ errors.layoutImageUrl?.message }
            label={ 'Layout image url' }
            margin='normal'
            minRows = { 3 }
            multiline
            name='layoutImageUrl'
            placeholder={ 'Layout image url' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('siteId') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            disabled={ true }
            error={ errors.siteId? true:false }
            helperText={ errors.siteId?.message }
            label={ 'Site ID' }
            margin='normal'
            minRows = { 3 }
            multiline
            name='siteId'
            placeholder={ 'Site ID' }
            required
            size='small'
            variant = 'outlined'
          />
          <FormControl>
            <FormLabel>Hidden</FormLabel>
            <Controller
              control={ control }
              name='hidden'
              render={
                ({ field }) => {
                  return (
                    <RadioGroup
                      { ...field }
                      onChange={
                        (e) => {
                          field.onChange('true' === e.target.value);
                        }
                      }
                      row
                      value={ field?.value !== undefined ? field.value : '' }
                    >
                      <FormControlLabel
                        control={ <Radio /> }
                        label='Yes'
                        value={ 'true' }
                      />
                      <FormControlLabel
                        control={ <Radio /> }
                        label='No'
                        value={ 'false' }
                      />

                    </RadioGroup>
                  );
                }
              }
              rules={ { required: true } }
            />
          </FormControl>
        </Stack>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                props.onCancel?.();
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='primary'
            onClick={ handleSubmit(onFormSubmit) }
            variant='contained'
          >
            { 'Save' }
          </Button>
        </Box>
      </form>
    </div>
  );
};

RoomEditor.propTypes = {
  // The function ((Room) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func.isRequired,
  // The function ((Room) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func.isRequired,
  // The room
  room: PropTypes.shape({
    hidden: PropTypes.bool,
    id: PropTypes.string.isRequired,
    layoutImageUrl: PropTypes.string,
    name: PropTypes.string,
    siteId: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default RoomEditor;
