/**
 * Quote editor.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actions as requestsActions } from 'ducks/requests';
import * as quotesMethods from 'resources/quotes';
import * as toasts from 'toasts';

const QuoteEditor = (props) => {

  // The error when saving changes.
  const [ error, setError ] = useState('');

  const dispatch = useDispatch();

  // The ID of the quote.
  const quoteId = props?.quote?.id || null;

  const isAddMode = !quoteId;

  // The validation schema.
  const schema = Joi.object({
    instructor: Joi.string().min(1).required(),
    quote: Joi.string().min(1).required(),
  });

  const { formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: {
      instructor: '',
      quote: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const onFormSubmit = (params) => {
    return isAddMode
      ? createQuote(params)
      : updateQuote(quoteId, params);
  };
  const createQuote = (params) => {
    setError('');

    dispatch(requestsActions.request(quotesMethods.createQuote, {
      quote: {
        ...params,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Quote could not be created.');
        setError(`Quote could not be created: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Quote was created.');
        props.onSave?.(result);
      },
    }));
  };

  const updateQuote = (id, params) => {

    setError('');

    dispatch(requestsActions.request(quotesMethods.updateQuote, {
      quote: {
        ...params,
        id: id,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Quote could not be updated.');
        setError(`Quote could not be updated: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Quote was updated.');
        props.onSave?.(result);
      },
    }));
  };

  // Fill in the form with values from the quote.
  useEffect(() => {
    const values = {};

    if (!isAddMode) {
      values.instructor = props?.quote?.instructor || '';
      values.quote = props?.quote?.quote || '';
    }
    reset(values);

  }, [ props.quote, reset, isAddMode ]);

  return (
    <div className='quote editor'>
      <h2 className='hdg hdg-md'>{ props.title }</h2>
      <form autoComplete='off'>
        <Stack className='fields' >
          <TextField
            { ...register('instructor') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.instructor? true:false }
            helperText={ errors.instructor?.message }
            label={ 'Instructor' }
            margin='normal'
            name='instructor'
            placeholder={ 'Instructor' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('quote') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.quote? true:false }
            helperText={ errors.quote?.message }
            label={ 'Quote' }
            margin='normal'
            minRows = { 3 }
            multiline
            name='quote'
            placeholder={ 'Quote' }
            required
            size='small'
            variant = 'outlined'
          />
        </Stack>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                props.onCancel?.();
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='primary'
            onClick={ handleSubmit(onFormSubmit) }
            variant='contained'
          >
            { 'Save' }
          </Button>
        </Box>
      </form>
    </div>
  );
};

QuoteEditor.propTypes = {
  // The function ((Quote) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func.isRequired,
  // The function ((Quote) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func.isRequired,
  // The quote
  quote: PropTypes.shape({
    id: PropTypes.string.isRequired,
    instructor: PropTypes.string,
    quote: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default QuoteEditor;
