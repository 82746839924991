/**
 * Main home page.
 */
import { Box, Typography, withStyles } from '@material-ui/core';
import CategoryIcon from '@mui/icons-material/Category';
import ChatIcon from '@mui/icons-material/Chat';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PercentIcon from '@mui/icons-material/Percent';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import RedeemIcon from '@mui/icons-material/Redeem';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Link } from 'react-router-dom';

import { styles } from 'css-common';
import './home.scss';

const Home = (_props) => {

  return (
    <Box
      sx={
        { alignItems: 'center',
          display: 'flex',
          flexDirection: { md: 'column', xs: 'column' },
          mb: 20,
          mt: 10,

          padding: 30 }
      }
    >
      <Typography>
         Welcome to
      </Typography>
      <Typography variant='h4'>
         1Rebel Data Layer Admin UI
      </Typography>
      <Divider sx={ { mb: 8, mt: 12, width: '100%' } }>
         Please select one item to continue
      </Divider>
      <Stack direction='row' spacing={ 5 }>

        <Chip
          clickable
          component={ Link }
          icon={ <PermDataSettingIcon /> }
          label='Ingestion'
          to={ '/ingestion' }
        />
        <Chip
          clickable
          component={ Link }
          icon={ <PercentIcon /> }
          label='Perks'
          to={ '/perks' }
        />
        <Chip
          clickable
          component={ Link }
          icon={ <ChatIcon /> }
          label='Quotes'
          to={ '/quotes' }
        />
        <Chip
          clickable
          component={ Link }
          icon={ <MeetingRoomIcon /> }
          label='Rooms'
          to={ '/rooms' }
        />
        <Chip
          clickable
          component={ Link }
          icon={ <RedeemIcon /> }
          label='Series'
          to={ '/series' }
        />
        <Chip
          clickable
          component={ Link }
          icon={ <CategoryIcon /> }
          label='Series Types'
          to={ '/series-types' }
        />
        <Chip
          clickable
          component={ Link }
          icon={ <FitnessCenterIcon /> }
          label='Spot Types'
          to={ '/spot-types' }
        />
      </Stack>
    </Box>
  );
};

Home.propTypes = {};

export default withStyles(styles)(Home);
