/**
 * Errors thrown when a response contains a status code that falls out of the range of 2xx.
 *
 * @module errors/response-error
 */

class ResponseError extends Error {
  constructor(message) {
    super(message || 'RESPONSE_ERROR');
    this.name = 'ResponseError';
  }
}

export default ResponseError;
