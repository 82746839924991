/**
 * Content.
 */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Auth from 'components/auth/auth';
import Home from 'components/home/home';
import IngestionList from 'components/ingestion/ingestion-list';
import PerkCreate from 'components/perks/perk-create';
import PerkDelete from 'components/perks/perk-delete';
import PerkEdit from 'components/perks/perk-edit';
import PerkList from 'components/perks/perk-list';
import QuoteCreate from 'components/quotes/quote-create';
import QuoteDelete from 'components/quotes/quote-delete';
import QuoteEdit from 'components/quotes/quote-edit';
import QuoteList from 'components/quotes/quote-list';
import RoomDelete from 'components/rooms/room-delete';
import RoomEdit from 'components/rooms/room-edit';
import RoomList from 'components/rooms/room-list';
import SeriesTypeCreate from 'components/series-types/series-type-create';
import SeriesTypeDelete from 'components/series-types/series-type-delete';
import SeriesTypeEdit from 'components/series-types/series-type-edit';
import SeriesTypeList from 'components/series-types/series-type-list';
import SeriesCreate from 'components/series/series-create';
import SeriesDelete from 'components/series/series-delete';
import SeriesEdit from 'components/series/series-edit';
import SeriesList from 'components/series/series-list';
import SpotTypeCreate from 'components/spot-types/spot-type-create';
import SpotTypeDelete from 'components/spot-types/spot-type-delete';
import SpotTypeEdit from 'components/spot-types/spot-type-edit';
import SpotTypeList from 'components/spot-types/spot-type-list';

import './content.scss';

const Content = (_props) => {
  return (
    <div className='brt-content'>
      <Routes>
        <Route element={ <Auth /> } exact path='/sign-in' />
        <Route element={ <Home /> } exact path='/home' />
        <Route element={ <IngestionList /> } exact path='/ingestion' />
        <Route element={ <Navigate replace to='/home' /> } path='/' />
        <Route element={ <PerkList /> } exact path='/perks' />
        <Route element={ <PerkCreate /> } exact path='/perks/new' />
        <Route element={ <PerkEdit /> } exact path='/perks/edit/:id' />
        <Route element={ <PerkDelete /> } exact path='/perks/delete/:id' />
        <Route element={ <QuoteList /> } exact path='/quotes' />
        <Route element={ <QuoteCreate /> } exact path='/quotes/new' />
        <Route element={ <QuoteEdit /> } exact path='/quotes/edit/:id' />
        <Route element={ <QuoteDelete /> } exact path='/quotes/delete/:id' />
        <Route element={ <RoomList /> } exact path='/rooms' />
        <Route element={ <RoomEdit /> } exact path='/rooms/edit/:id' />
        <Route element={ <RoomDelete /> } exact path='/rooms/delete/:id' />
        <Route element={ <SeriesList /> } exact path='/series' />
        <Route element={ <SeriesCreate /> } exact path='/series/new' />
        <Route element={ <SeriesEdit /> } exact path='/series/edit/:id' />
        <Route element={ <SeriesDelete /> } exact path='/series/delete/:id' />
        <Route element={ <SeriesTypeList /> } exact path='/series-types' />
        <Route element={ <SeriesTypeCreate /> } exact path='/series-types/new' />
        <Route element={ <SeriesTypeEdit /> } exact path='/series-types/edit/:id' />
        <Route element={ <SeriesTypeDelete /> } exact path='/series-types/delete/:id' />
        <Route element={ <SpotTypeList /> } exact path='/spot-types' />
        <Route element={ <SpotTypeCreate /> } exact path='/spot-types/new' />
        <Route element={ <SpotTypeEdit /> } exact path='/spot-types/edit/:id' />
        <Route element={ <SpotTypeDelete /> } exact path='/spot-types/delete/:id' />
      </Routes>
    </div>
  );
};

Content.propTypes = {
};

Content.defaultProps = {
};

export default Content;
