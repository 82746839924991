/**
 * Duck for ingestion.
 *
 * @module ducks/ingestion
 */

/**
 * The type of actions to describe that the ingestion data have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const INGESTION_SET = 'INGESTION_SET';
const INGESTION_SORT_TABLE = 'INGESTION_SORT_TABLE';
const INGESTION_FILTER_TABLE = 'INGESTION_FILTER_TABLE';
const INGESTION_PAGINATION_TABLE = 'INGESTION_PAGINATION_TABLE';

/**
 * Sanitizes a date string that is in the form YYYY-MM-DDThh:mm:ss.sssTZD
 * (e.g. '1970-01-01T00:00:00.000+00:00') by removing the 'T' and the timezone offset.
 *
 * @param {string} dateString - The string to sanitize.
 * @returns {string} - The sanitized string.
 */
const sanitizeDate = (dateString) => {
  if ( dateString ) {
    return dateString.replace('T', ' ').substring(0, 19);
  }
  return '';
};

/**
 * The mapping of status codes numerals (returned from the back end) to status codes literals.
 */
const statusCodesDict = {
  0: 'SUCCESS',
  1: 'ERRORS',
  2: 'FAILURE',
  3: 'RETRY',
  4: 'IN PROGRESS',
};

/**
  *  Creates an action to set the ingestion records to the given ingestion records.
  *
  * @param {module:types/ingestion~Ingestion[]} ingestionRecords - The ingestion records.
  * @returns {object} - The action.
  * @static
  */
const setIngestionRecords = (ingestionRecords) => {
  return {
    ingestionRecords,
    type: INGESTION_SET,
  };
};

const setIngestionSortTable = (sortModel) => {
  return {
    sortModel,
    type: INGESTION_SORT_TABLE,
  };
};

const setIngestionFilterTable = (filterModel) => {
  return {
    filterModel,
    type: INGESTION_FILTER_TABLE,
  };
};

const setIngestionPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: INGESTION_PAGINATION_TABLE,
  };
};

/**
  * Ingestion state.
  *
  * @typedef {object} State
  * @property {module:types/ingestion~Ingestion[]} ingestionRecords - The ingestion records.
  * @property {module:types/ingestion~SortModel} sortModel - The sort model for the ingestion table
  * @property {module:types/ingestion~FilterModel} filterModel - The filter model for the ingestion table
  * @property {module:types/ingestion~PaginationModel} paginationModel - The pagination model for the ingestion table
  */

/**
   * The initial ingestion state.
   *
   * @type {module:ducks/ingestion~State}
   * @static
   */
const initialState = {
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  ingestionRecords: [],
  paginationModel: { page: 0 },
  sortModel: [ { field: 'name', sort: 'asc' } ],
};

/**
   * Applies the given action to the given state and returns the new state.
   *
   * @param {module:ducks/ingestion~State} state - The previous state.
   * @param {object} action - The action.
   * @returns {module:ducks/ingestion~State} - The new state.
   * @static
   */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case INGESTION_SET:
    return {
      ...state,
      ingestionRecords: action.ingestionRecords.map((r) => {
        return {
          ...r,
          // Alter the date strings to make them more human readable, and translate code to message
          ended: sanitizeDate(r.ended),
          next: sanitizeDate(r.next),
          started: sanitizeDate(r.started),
          status_code_message: statusCodesDict[r.status_code]||'UNKNOWN',
          status_time: sanitizeDate(r.status_time),
        };
      }),
    };
  case INGESTION_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case INGESTION_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case INGESTION_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  setIngestionFilterTable,
  setIngestionPaginationTable,
  setIngestionRecords,
  setIngestionSortTable,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
