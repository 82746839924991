/**
 * Spot Type editor.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actions as requestsActions } from 'ducks/requests';
import * as spotTypesMethods from 'resources/spot-types';
import * as toasts from 'toasts';

import './spot-type-editor.scss';

const SpotTypeEditor = (props) => {

  // The error when saving changes.
  const [ error, setError ] = useState('');

  const dispatch = useDispatch();

  // The ID of the Spot Type.
  const spotTypeId = props?.spotType?.id || null;

  const isAddMode = !spotTypeId;

  // The validation schema.
  const schema = Joi.object({
    iconImageUrl: Joi.string().uri().required(),
    name: Joi.string().min(1).required(),
  });

  const { formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: {
      iconImageUrl: '',
      name: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const onFormSubmit = (params) => {
    return isAddMode
      ? createSpotType(params)
      : updateSpotType(spotTypeId, params);
  };
  const createSpotType = (params) => {
    setError('');

    dispatch(requestsActions.request(spotTypesMethods.createSpotType, {
      spotType: {
        ...params,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Spot Type could not be created.');
        setError(`Spot Type could not be created: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Spot Type was created.');
        props.onSave?.(result);
      },
    }));
  };

  const updateSpotType = (id, params) => {

    setError('');

    dispatch(requestsActions.request(spotTypesMethods.updateSpotType, {
      spotType: {
        ...params,
        id: id,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Spot Type could not be updated.');
        setError(`Spot Type could not be updated: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Spot Type was updated.');
        props.onSave?.(result);
      },
    }));
  };

  // Fill in the form with values from the Spot Type.
  useEffect(() => {
    const values = {};

    if (!isAddMode) {
      values.name = props?.spotType?.name || null;
      values.iconImageUrl = props?.spotType?.iconImageUrl || '';
    }
    reset(values);

  }, [ props.spotType, reset, isAddMode ]);

  return (
    <div className='spo-type editor'>
      <h2 className='hdg hdg-md'>{ props.title }</h2>
      <form autoComplete='off'>
        <Stack className='fields' >
          <TextField
            { ...register('name') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.name? true:false }
            helperText={ errors.name?.message }
            label={ 'Name' }
            margin='normal'
            name='name'
            placeholder={ 'Name' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('iconImageUrl') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.iconImageUrl? true:false }
            helperText={ errors.iconImageUrl?.message }
            label={ 'Icon Image url' }
            margin='normal'
            name='iconImageUrl'
            placeholder={ 'Icon image url' }
            required
            size='small'
            variant = 'outlined'
          />
        </Stack>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                props.onCancel?.();
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='primary'
            onClick={ handleSubmit(onFormSubmit) }
            variant='contained'
          >
            { 'Save' }
          </Button>
        </Box>
      </form>
    </div>
  );
};

SpotTypeEditor.propTypes = {
  // The function ((Perk) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func.isRequired,
  // The function ((Perk) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func.isRequired,
  // The perk
  spotType: PropTypes.shape({
    iconImageUrl: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default SpotTypeEditor;
