/**
  * Resource method to get sites.
  *
  * @module resources/sites/get-sites
  */
import axios from 'axios';

/**
  * Gets the sites.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @returns {Promise<module/resources/sites/get-sites~Result>} - The promise.
  * @static
  */
const getSites = () => {
  return axios.request({
    method: 'get',
    url: '/sites',
  }).then((response) => {
    return response.data;
  });
};

export default getSites;
