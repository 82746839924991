import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions as requestsActions } from 'ducks/requests';
import { actions as spotTypesActions } from 'ducks/spot-types';
import * as spotTypesMethods from 'resources/spot-types/index';
import * as toasts from 'toasts';

import SpotTypeEditor from './spot-type-editor/spot-type-editor';

const SpotTypeEdit = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(spotTypesMethods.getSpotType, id, {
      onFailure: () => {
        toasts.info('Spot Type could not be loaded.');
      },
      onSuccess: (result) => {
        dispatch(spotTypesActions.setSpotType(result));
      },
    }));
  }, [ dispatch, id ]);

  const spotTypeToEdit = useSelector((store) => {
    return store.spotTypes.spotType || {};
  }, shallowEqual);

  return (
    <div>
      <h2>Spot Types</h2>
      <SpotTypeEditor
        onCancel={
          () => {
            navigate('/spot-types');
          }
        }
        onSave={
          () => {
            navigate('/spot-types');
          }
        }
        spotType={ spotTypeToEdit }
        title= { 'Edit Spot Type' }
      />
    </div>
  );
};

export default SpotTypeEdit;
