/**
 * Errors thrown when something goes wrong while setting up a request.
 *
 * @module errors/request-setup-error
 */

class RequestSetupError extends Error {
  constructor(message) {
    super(message || 'REQUEST_SETUP_ERROR');
    this.name = 'RequestSetupError';
  }
}

export default RequestSetupError;
