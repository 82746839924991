/**
 * Duck for spot-types.
 *
 * @module ducks/spotTypes
 */

/**
 * The type of actions to describe that the spotTypes have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const SPOT_TYPES_SET = 'SPOT_TYPES_SET';
const SPOT_TYPE_SET = 'SPOT_TYPE_SET';
const SPOT_TYPE_CREATE = 'SPOT_TYPE_CREATE';
const SPOT_TYPE_EDIT = 'SPOT_TYPE_EDIT';
const SPOT_TYPE_DELETE = 'SPOT_TYPE_DELETE';
const SPOT_TYPES_SORT_TABLE = 'SPOT_TYPES_SORT_TABLE';
const SPOT_TYPES_FILTER_TABLE = 'SPOT_TYPES_FILTER_TABLE';
const SPOT_TYPES_PAGINATION_TABLE = 'SPOT_TYPES_PAGINATION_TABLE';

/**
  *  Creates an action to set the spot types to the given spotTypes.
  *
  * @param {module:types/spot-types~SpotType[]} spotTypes - The spotTypes.
  * @returns {object} - The action.
  * @static
  */

const setSpotTypes = (spotTypes) => {
  return {
    spotTypes,
    type: SPOT_TYPES_SET,
  };
};

const createSpotType = (spotType) => {
  return {
    spotType,
    type: SPOT_TYPE_CREATE,
  };
};

const updateSpotType = (spotType) => {
  return {
    spotType,
    type: SPOT_TYPE_EDIT,
  };
};

const deleteSpotType = (id) => {
  return {
    id,
    type: SPOT_TYPE_DELETE,
  };
};

const setSpotType = (spotType) => {
  return {
    spotType,
    type: SPOT_TYPE_SET,
  };
};

const setSpotTypesSortTable = (sortModel) => {
  return {
    sortModel,
    type: SPOT_TYPES_SORT_TABLE,
  };
};

const setSpotTypesFilterTable = (filterModel) => {
  return {
    filterModel,
    type: SPOT_TYPES_FILTER_TABLE,
  };
};

const setSpotTypesPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: SPOT_TYPES_PAGINATION_TABLE,
  };
};

/**
  * Spot-types state.
  *
  * @typedef {object} State
  * @property {module:types/spotTypes~SpotType[]} spotTypes - The different spotTypes.
  * @property {module:types/spotTypes~SpotType} spotType - The current spotType.
  * @property {module:types/spotTypes~SortModel} sortModel - The sort model for the spotTypes table
  * @property {module:types/spotTypes~FilterModel} filterModel - The filter model for the spotTypes table
  * @property {module:types/spotTypes~PaginationModel} paginationModel - The pagination model for the spotTypes table
  */

/**
   * The initial spotTypes state.
   *
   * @type {module:ducks/spotTypes~State}
   * @static
   */
const initialState = {
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  paginationModel: { page: 0 },
  sortModel: [ { field: 'name', sort: 'asc' } ],
  spotType: undefined,
  spotTypes: [],
};

/**
   * Applies the given action to the given state and returns the new state.
   *
   * @param {module:ducks/spotTypes~State} state - The previous state.
   * @param {object} action - The action.
   * @returns {module:ducks/spotTypes~State} - The new state.
   * @static
   */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SPOT_TYPES_SET:
    return {
      ...state,
      spotType: undefined,
      spotTypes: action.spotTypes,
    };
  case SPOT_TYPE_SET:
    return {
      ...state,
      spotType: action.spotType,
    };
  case SPOT_TYPE_CREATE:
    return {
      ...state,
      spotType: action.spotType,
    };
  case SPOT_TYPE_EDIT:
    return {
      ...state,
      spotType: action.spotType,
    };
  case SPOT_TYPE_DELETE:
    return {
      ...state,
      spotTypes: state.spotTypes.filter((spotType) => {
        return action.id !== spotType.id.toString();
      }),
    };
  case SPOT_TYPES_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case SPOT_TYPES_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case SPOT_TYPES_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  createSpotType,
  deleteSpotType,
  setSpotType,
  setSpotTypes,
  setSpotTypesFilterTable,
  setSpotTypesPaginationTable,
  setSpotTypesSortTable,
  updateSpotType,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
