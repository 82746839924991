/**
 * Resource method to get all series.
 *
 * @module resources/series/get-all-series
 */
import axios from 'axios';

/**
 * Gets the series.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/series/get-all-series~Result>} - The promise.
 * @static
 */
const getAllSeries = () => {
  return axios.request({
    method: 'get',
    url: '/series/',
  }).then((response) => {
    return response.data;
  });
};

export default getAllSeries;
