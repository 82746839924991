/**
 * Resource method to get ingestion records.
 *
 * @module resources/ingestion/trigger-ingestion
 */
import axios from 'axios';

/**
 * Triggers a new ingestion.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/ingestion/trigger-ingestion~Result>} - The promise.
 * @static
 */
const triggerIngestion = () => {
  return axios.request({
    method: 'post',
    url: '/ingestion/trigger',
  }).then((response) => {
    return response.data;
  });
};

export default triggerIngestion;
