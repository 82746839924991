import React from 'react';
import { useNavigate } from 'react-router-dom';

import QuoteEditor from './quote-editor/quote-editor';
import './quote-create.scss';

const QuoteCreate = () => {

  const navigate = useNavigate();

  return (
    <div>
      <h2>Quotes</h2>
      <QuoteEditor
        onCancel={
          () => {
            navigate('/quotes');
          }
        }
        onSave={
          () => {
            navigate('/quotes');
          }
        }
        title='Create Quote'
      />
    </div>
  );
};

export default QuoteCreate;
