import React from 'react';
import { useNavigate } from 'react-router-dom';

import PerkEditor from './perk-editor/perk-editor';
import './perk-create.scss';

const PerkCreate = () => {

  const navigate = useNavigate();

  return (
    <div>
      <h2>Perks</h2>
      <PerkEditor
        onCancel={
          () => {
            navigate('/perks');
          }
        }
        onSave={
          () => {
            navigate('/perks');
          }
        }
        title='Create Perk'
      />
    </div>
  );
};

export default PerkCreate;
