/**
 * Duck for authn/authz.
 *
 * @module ducks/auth
 */

/**
  * The type of actions to describe that a user has signed in.
  *
  * @constant {string}
  * @default
  * @static
  */
const USER_SIGNED_IN = 'USER_SIGNED_IN';

/**
  * The type of actions to describe that the signed-in user has signed out.
  *
  * @constant {string}
  * @default
  * @static
  */
const USER_SIGNED_OUT = 'USER_SIGNED_OUT';

/**
  * Creates an action to sign in the user.
  *
  * @param {module:types/userToken } userToken - The user token.
  * @returns {object} - The action.
  * @static
  */
const signIn = (userToken) => {
  const accessToken = userToken;
  return {
    accessToken,
    type: USER_SIGNED_IN,
  };
};

/**
  * Creates an action to sign out the user that is currently signed-in.
  *
  * @returns {object} - The action.
  * @static
  */
const signOut = () => {
  return {
    type: USER_SIGNED_OUT,
  };
};

/**
  * Authn/authz state.
  *
  * @typedef {object} State
  */

/**
  * The initial authn/authz state.
  *
  * @type {module:ducks/auth~State}
  * @static
  */
const initialState = {
  accessToken: undefined,
};

/**
  * Applies the given action to the given state and returns the new state.
  *
  * @param {module:ducks/auth~State} state - The previous state.
  * @param {object} action - The action.
  * @returns {module:ducks/auth~State} - The new state.
  * @static
  */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case USER_SIGNED_IN:
    return {
      ...state,
      accessToken: action.accessToken,
    };
  case USER_SIGNED_OUT:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const actions = {
  signIn,
  signOut,
};

const types = {
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
};

export {
  actions,
  reducer,
  types,
};
