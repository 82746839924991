export const styles = {
  appBar: {
    '& .MuiToolbar-regular': {
      minHeight: '50px',
    },
    backgroundColor: '#c4c4c4',
    height: '50px',
  },
  link: {
    color: '#242A30',
    margin: '0 20px',
    textDecoration: 'unset',
    textTransform: 'unset',
  },
  name: {
    marginRight: '15px',
  },
};
