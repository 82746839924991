import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes, { any, arrayOf } from 'prop-types';
import React from 'react';

const SimpleTable = (props) => {

  return (
    <Box sx={ { height: '100%', width: 1 } }>

      <div style={ { width: '100%' } }>

        <DataGrid
          autoHeight
          columns={ props.columns }
          getRowHeight={ () => { return 'auto'; } }
          getRowId={ (row) => { return row.field; } }
          headerHeight={ 0 }
          hideFooter={ true }
          initialState={
            {
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [],
                },
              },
            }
          }
          pagination
          rowHeight={ 40 }
          rows={ props.rows }
        />
      </div>
    </Box>
  );
};

const ColumnFields = PropTypes.shape({
  field: PropTypes.string,
  headerName: PropTypes.string,
  minWidth: PropTypes.number,
  type: PropTypes.string,
  width: PropTypes.number,
});

const RowFields = PropTypes.shape({
  field: PropTypes.string,
  fieldValue: any,
});

SimpleTable.propTypes = {
  columns: arrayOf(ColumnFields),
  header: PropTypes.bool,
  rows: arrayOf(RowFields),
};

export default SimpleTable;
