import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PropTypes, { any, arrayOf } from 'prop-types';
import React, { useState } from 'react';

const DataTable = (props) => {

  const [ quickSearchText, setQuickSearchText ] = useState(
    props.filterModel.quickFilterValues? props.filterModel.quickFilterValues.join(' '):''
  );

  // we need to define our own quickFilterParser function in order to save
  // the searchText in state and initialise the search field.
  // If we directly use the joined quickFilterValues form the store, the user
  // can never enter space, because it gets filtered
  const searchValueParser = (searchText) => {
    setQuickSearchText(searchText);
    return searchText.split(' ').filter((word) => { return '' !== word; });
  };

  return (
    <Box sx={ { height: '100%', width: 1 } }>

      <div style={ { width: '100%' } }>
        <DataGrid
          autoHeight
          columns={
            props.columns.map( (column) => {
              // If the column has an explicit custom  cell rendering, or if it's not a string or number (e.g. booleans
              // in datagrid are being natively rendered in a custom way), do not alter the structure of the cell
              if (column.renderCell || ('number' !== column.type && 'string' !== column.type)) {
                return column;
              }
              // Add a tooltip so that if the value is overflowing, the full content is still available via the tooltip
              return {
                ...column,
                renderCell: (params) => {
                  return (
                    <span
                      style={ { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }
                      title={ params.value }
                    >
                      { params.value }
                    </span>
                  );
                },
              };
            })
          }
          components={ { Toolbar: GridToolbar } }
          componentsProps={
            {
              toolbar: {
                quickFilterProps: {
                  debounceMs: 10,
                  quickFilterParser: searchValueParser,
                  // value is not documented in mui GridToolbar
                  value: quickSearchText,
                },
                showQuickFilter: true,
              },
            }
          }
          disableColumnSelector
          disableDensitySelector
          filterModel = { props.filterModel }
          initialState={
            {
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [],
                },
              },
            }
          }
          onFilterModelChange = { (model) => { props?.onFilterListModel?.(model); } }
          onPageChange = { (model) => { props?.onPaginationModel?.(model); } }
          onSortModelChange={ (model) => { props?.onSortListModel?.(model); } }
          page = { props.paginationModel.page }
          pageSize={ props.pageSize }
          rowHeight={ 40 }
          rows={ props.rows }
          rowsPerPageOptions={ props.rowsPerPageOptions }
          sortModel={ props.sortModel }
        />
      </div>
    </Box>
  );
};

const ColumnFields = PropTypes.shape({
  field: PropTypes.string,
  headerName: PropTypes.string,
  minWidth: PropTypes.number,
  type: PropTypes.string,
  width: PropTypes.number,
});

DataTable.propTypes = {
  columns: arrayOf(ColumnFields),
  filterModel: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      columnField: PropTypes.string,
      operatorValue: PropTypes.string,
      value: PropTypes.string,
    })),
    quickFilterLogicOperator: PropTypes.oneOf([ 'or', 'and' ]),
    quickFilterValues: arrayOf(PropTypes.string),
  }),
  onFilterListModel: PropTypes.func,
  onPaginationModel: PropTypes.func,
  onSortListModel: PropTypes.func,
  pageSize: PropTypes.number,
  paginationModel: PropTypes.shape({
    page: PropTypes.number,
  }),
  rows: arrayOf(any),
  rowsPerPageOptions: arrayOf(any),
  sortModel: PropTypes.arrayOf( PropTypes.shape({
    field: PropTypes.string,
    sort: PropTypes.string,
  })),
};

export default DataTable;
