/**
 * Environment.
 *
 * @module config/env
 */

/**
 * The back-end base URL.
 *
 * This constant gets its value from the `REACT_APP_BACKEND_BASE_URL` environment variable.
 *
 * @constant {string}
 * @default 'https://britney-dev-admin-api.programize.com'
 * @static
 */
const BACKEND_BASE_URL = process.env['REACT_APP_BACKEND_BASE_URL'] || 'http://localhost:8080';

/**
 * The application public URL.
 *
 * This constant gets its value from the `REACT_APP_PUBLIC_URL` environment variable.
 *
 * @constant {string}
 * @default 'http://localhost:3000'
 * @static
 */
const PUBLIC_URL = process.env['REACT_APP_PUBLIC_URL'] || 'http://localhost:3000';

/**
 * The request timeout.
 *
 * This constant gets its value from the `REACT_APP_REQUEST_TIMEOUT` environment variable.
 *
 * @constant {number}
 * @static
 */
const REQUEST_TIMEOUT = parseInt(process.env['REACT_APP_REQUEST_TIMEOUT']) || 10;

/**
 * The upload timeout in seconds.
 *
 * This constant gets its value from the `REACT_APP_UPLOAD_TIMEOUT` environment variable.
 *
 * @constant {number}
 * @static
 */
const UPLOAD_TIMEOUT = parseInt(process.env['REACT_APP_UPLOAD_TIMEOUT']) || 60;

export default {
  BACKEND_BASE_URL,
  PUBLIC_URL,
  REQUEST_TIMEOUT,
  UPLOAD_TIMEOUT,
};
