/**
 * Middleware for requests.
 *
 * @module middlewares/requests
 */
import axios from 'axios';

import env from 'config/env';
import { actions as authActions, types as authTypes } from 'ducks/auth';
import { actions as requestsActions, types as requestsTypes } from 'ducks/requests';
import RequestError from 'errors/request-error';
import RequestSetupError from 'errors/request-setup-error';
import ResponseError from 'errors/response-error';
import UnauthorizedError from 'errors/unauthorized-error';

// Set the base URL for all requests.
axios.defaults.baseURL = env.BACKEND_BASE_URL;

// Set some common request headers.
axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
axios.defaults.headers.common['Pragma'] = 'no-cache';

// Register a request interceptor.
axios.interceptors.request.use((config) => {
  // Set the request timeout.
  config.timeout = (config.data instanceof FormData ? env.UPLOAD_TIMEOUT : env.REQUEST_TIMEOUT) * 1000;
  config.withCredentials = true;
  return config;
});

// Register a response interceptor.
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const { config } = error;

  // The request that failed was a sign-in attempt.
  if ('/sign-in' === config.url) {
    throw new UnauthorizedError(error.response?.data);
  }

  if (undefined !== error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx.
    // 401 (UNAUTHORIZED) was returned.
    if ( -1 < [ 401, 403 ].indexOf(error.response.status)) {
      throw new UnauthorizedError();
    }
    throw new ResponseError(error.response.data);
  } else if (undefined !== error.request) {
    // The request was made but no response was received.
    throw new RequestError();
  } else {
    // Something happened when setting up the request that triggered an error.
    throw new RequestSetupError();
  }
});

const middleware = (store) => {
  return (next) => {
    return (action) => {
      const { type } = action;

      // Some user signed in.
      if (authTypes.USER_SIGNED_IN === type) {
        const { accessToken } = action;
        // Use the user's access token for any subsequent requests.
        axios.defaults.headers.common['Authorization'] = `Bearer ${ accessToken }`;
        return next(action);
      }

      // The signed-in user signed out.
      if (authTypes.USER_SIGNED_OUT === type) {
        // Do not use the user's access token any more.
        axios.defaults.headers.common['Authorization'] = undefined;
        return next(action);
      }

      if (requestsTypes.REQUEST_MADE !== type) {
        return next(action);
      }
      // workaround to fix being logged out after refresh
      if (axios.defaults.headers.common['Authorization'] === undefined) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${ store.getState().auth.accessToken }`;
      }
      // Make the actual request.
      store.dispatch(requestsActions.startRequest());
      return action.method.call(null, action.params).then((result) => {
        // The request succeeded.
        action.options?.onSuccess?.(result);
      }).catch((error) => {
        // The request failed.
        if (error instanceof UnauthorizedError && '/sign-in' !== window.location.pathname) {
          // Access was denied, and the request was not a sign-in attempt.
          store.dispatch(authActions.signOut());
          axios.defaults.headers.common['Authorization'] = undefined;
          action.options?.onFailure?.(error);
        } else {
          // Something else went wrong.
          action.options?.onFailure?.(error);
        }
      }).finally(() => {
        store.dispatch(requestsActions.endRequest());
        action.options?.onCompletion?.();
      });
    };
  };
};

export {
  middleware,
};
