/**
 * Resource method to create a quote.
 *
 * @module resources/quotes/create-quote
 */
import axios from 'axios';

/**
  * Params.
  *
  *
  * @typedef {object} Params
  * @property {string} instructor
  * @property {string} quote
  */

/**
  * Result.
  *
  * @typedef {object} Result
  * @property {string} id - The ID of the quote.
  */

/**
  * Creates a quote based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/quotes/create-quote~Params} params - The parameters.
  * @returns {Promise<module:resources/quotes/create-quote~Result>} - The promise.
  * @static
  */
const createQuote = (params) => {
  return axios.request({
    data: JSON.stringify(params.quote),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/quotes',
  }).then((response) => {
    return response.data;
  });
};

export default createQuote;
