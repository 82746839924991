/**
 * Duck for series types.
 *
 * @module ducks/seriesTypes
 */

/**
 * The type of actions to describe that the series-types have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const SERIES_TYPES_SET = 'SERIES_TYPES_SET';
const SERIES_TYPES_MAP_SET = 'SERIES_TYPES_MAP_SET';
const SERIES_TYPE_SET = 'SERIES_TYPE_SET';
const SERIES_TYPE_CREATE = 'SERIES_TYPE_CREATE';
const SERIES_TYPE_EDIT = 'SERIES_TYPE_EDIT';
const SERIES_TYPE_DELETE = 'SERIES_TYPE_DELETE';
const SERIES_TYPES_SORT_TABLE = 'SERIES_TYPES_SORT_TABLE';
const SERIES_TYPES_FILTER_TABLE = 'SERIES_TYPES_FILTER_TABLE';
const SERIES_TYPES_PAGINATION_TABLE = 'SERIES_TYPES_PAGINATION_TABLE';

/**
  *  Creates an action to set the series types to the given series types.
  *
  * @param {module:types/seriesTypes~SeriesType[]} seriesTypes - The series types.
  * @returns {object} - The action.
  * @static
  */

const setSeriesTypes = (seriesTypes) => {
  return {
    seriesTypes,
    type: SERIES_TYPES_SET,
  };
};

const setSeriesTypesMap = (seriesTypesMap) => {
  return {
    seriesTypesMap,
    type: SERIES_TYPES_MAP_SET,
  };
};

const createSeriesType = (seriesType) => {
  return {
    seriesType,
    type: SERIES_TYPE_CREATE,
  };
};

const updateSeriesType = (seriesType) => {
  return {
    seriesType,
    type: SERIES_TYPE_EDIT,
  };
};

const deleteSeriesType = (id) => {
  return {
    id,
    type: SERIES_TYPE_DELETE,
  };
};

const setSeriesType = (seriesType) => {
  return {
    seriesType,
    type: SERIES_TYPE_SET,
  };
};

const setSeriesTypesSortTable = (sortModel) => {
  return {
    sortModel,
    type: SERIES_TYPES_SORT_TABLE,
  };
};

const setSeriesTypesFilterTable = (filterModel) => {
  return {
    filterModel,
    type: SERIES_TYPES_FILTER_TABLE,
  };
};

const setSeriesTypesPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: SERIES_TYPES_PAGINATION_TABLE,
  };
};

/**
  * Series Types state.
  *
  * @typedef {object} State
  * @property {module:types/seriesTypes~SeriesType[]} seriesTypes - The different series types.
  * @property {module:types/seriesTypes~SeriesType} seriesType - The current series type.
  * @property {module:types/seriesTypes~SeriesType} seriesTypesMap - The series types as map with id as key.
  * @property {module:types/seriesTypes~SortModel} sortModel - The sort model for the seriesTypes table
  * @property {module:types/seriesTypes~FilterModel} filterModel - The filter model for the seriesTypes table
  * @property {module:types/seriesTypes~PaginationModel} paginationModel - The pagination model for seriesTypes table
  */

/**
   * The initial series types state.
   *
   * @type {module:types/seriesTypes~State}
   * @static
   */
const initialState = {
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  paginationModel: { page: 0 },
  seriesType: undefined,
  seriesTypes: [],
  seriesTypesMap: {},
  sortModel: [ { field: 'name', sort: 'asc' } ],
};

/**
   * Applies the given action to the given state and returns the new state.
   *
   * @param {module:ducks/seriesTypes~State} state - The previous state.
   * @param {object} action - The action.
   * @returns {module:ducks/seriesTypes~State} - The new state.
   * @static
   */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SERIES_TYPES_SET:
    return {
      ...state,
      seriesType: undefined,
      seriesTypes: action.seriesTypes,
    };
  case SERIES_TYPES_MAP_SET:
    return {
      ...state,
      seriesTypesMap: action.seriesTypesMap,
    };
  case SERIES_TYPE_SET:
    return {
      ...state,
      seriesType: action.seriesType,
    };
  case SERIES_TYPE_CREATE:
    return {
      ...state,
      seriesType: action.seriesType,
    };
  case SERIES_TYPE_EDIT:
    return {
      ...state,
      seriesType: action.seriesType,
    };
  case SERIES_TYPE_DELETE:
    return {
      ...state,
      seriesTypes: state.seriesTypes.filter((seriesType) => {
        return action.id !== seriesType.id.toString();
      }),
    };
  case SERIES_TYPES_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case SERIES_TYPES_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case SERIES_TYPES_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  createSeriesType,
  deleteSeriesType,
  setSeriesType,
  setSeriesTypes,
  setSeriesTypesFilterTable,
  setSeriesTypesMap,
  setSeriesTypesPaginationTable,
  setSeriesTypesSortTable,
  updateSeriesType,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
