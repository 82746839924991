/**
 * Resource method to get spot-types.
 *
 * @module resources/spot-types/get-spot-types
 */
import axios from 'axios';

/**
 * Gets the spot-types.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/spot-types/get-spot-types~Result>} - The promise.
 * @static
 */
const getSpotTypes = () => {
  return axios.request({
    method: 'get',
    url: '/spot-types/',
  }).then((response) => {
    return response.data;
  });
};

export default getSpotTypes;
