import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/common/table/data-table';
import { actions as ingestionActions } from 'ducks/ingestion';
import { actions as requestsActions } from 'ducks/requests';
import * as ingestionMethods from 'resources/ingestion/index';
import * as toasts from 'toasts';

const IngestionList = () => {
  const initialState = {
    filter: { filterModel: {
      items: [],
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    } },
    pagination: { page: 0 },
    sorting: [ { field: 'name', sort: 'asc' } ],
  };

  const navigate = useNavigate();

  const sortModel = useSelector((store) => {
    return store?.ingestion?.sortModel || initialState.sorting;
  }, shallowEqual);

  const filterModel = useSelector((store) => {
    return store?.ingestion?.filterModel || initialState.filter.filterModel;
  }, shallowEqual);

  const paginationModel = useSelector((store) => {
    return store?.ingestion?.paginationModel || initialState.pagination;
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestsActions.request(ingestionMethods.getIngestionRecords, null, {
      onFailure: (error) => {
        const message = 'Ingestion data could not be loaded.';
        if ('UnauthorizedError' === error.name) {
          toasts.info(`${ message } Are you signed in?`);
          navigate('/sign-in', { state: { from: '/ingestion' } });
        } else {
          toasts.info(message);
        }
      },
      onSuccess: (result) => {
        dispatch(ingestionActions.setIngestionRecords(result));
      },
    }));
  }, [ dispatch, navigate ]);

  const ingestionRecords = useSelector((store) => {
    return store.ingestion.ingestionRecords || [];
  }, shallowEqual);

  const triggerIngestion = () => {

    dispatch(requestsActions.request(ingestionMethods.triggerIngestion, null, {
      onFailure: (_error) => {
        toasts.info(`Failed to trigger a new ingestion: ${ _error }`);
      },
      onSuccess: (response) => {
        toasts.info(`${ response.statusMessage }`);
      },
    }));
  };

  const renderTriggerIngestion = () => {
    return (
      <div style={ { paddingBottom: '15px', paddingTop: '15px', textAlign: 'left' } }>
        <Button
          color='primary'
          onClick={ triggerIngestion }
          variant='contained'
        >
          Trigger a full ingestion
        </Button>
      </div>
    );
  };

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 120, type: 'string' },
    { field: 'status_code_message', headerName: 'Status', type: 'string', width: 115 },
    { field: 'status', headerName: 'Status message', minWidth: 580, type: 'string' },
    {
      description: 'The time that the Status message was generated.',
      field: 'status_time',
      headerName: 'Status Time',
      minWidth: 160,
      type: 'string',
    },
    {
      description: 'The time that the last ingestion for this entity started.',
      field: 'started',
      headerName: 'Started',
      minWidth: 160,
      type: 'string',
    },
    {
      description: 'The time that the last ingestion for this entity ended.',
      field: 'ended',
      headerName: 'Ended',
      minWidth: 160,
      type: 'string',
    },
    {
      description: 'The time that the next ingestion for this entity is scheduled at.',
      field: 'next',
      headerName: 'Next',
      minWidth: 160,
      type: 'string',
    },
    { field: 'ingested', headerName: 'Ingested', minWidth: 50, type: 'number' },
    { field: 'failed', headerName: 'Failed', minWidth: 50, type: 'number' },
    { field: 'retries', headerName: 'Retries', minWidth: 50, type: 'number' },
  ];

  const setSortModelToStore = (sortModel) => {
    dispatch(ingestionActions.setIngestionSortTable(sortModel));
  };

  const setFilterModelToStore = (filterModel) => {
    dispatch(ingestionActions.setIngestionFilterTable(filterModel));
  };

  const setPaginationModelToStore = (paginationModel) => {
    dispatch(ingestionActions.setIngestionPaginationTable({ page: paginationModel }));
  };

  return (
    <div>
      <h2>Ingestion Overview</h2>
      <div className='ui celled list'>
        <DataTable
          columns={ columns }
          filterModel = { filterModel }
          onFilterListModel = { setFilterModelToStore }
          onPaginationModel = { setPaginationModelToStore }
          onSortListModel = { setSortModelToStore }
          pageSize={ 50 }
          paginationModel = { paginationModel }
          rows={ ingestionRecords }
          rowsPerPageOptions={ [ 50 ] }
          sortModel={ sortModel }
        />
      </div>
      { renderTriggerIngestion() }
    </div>
  );
};

export default IngestionList;
