/**
 * Resource method to get ingestion records.
 *
 * @module resources/ingestion/get-ingestion-records
 */
import axios from 'axios';

/**
 * Gets the ingestion records.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/ingestion/get-ingestion-records~Result>} - The promise.
 * @static
 */
const getIngestionRecords = () => {
  return axios.request({
    method: 'get',
    url: '/ingestion',
  }).then((response) => {
    return response.data;
  });
};

export default getIngestionRecords;
