/**
 * Resource method to create a series.
 *
 * @module resources/series/create-series
 */
import axios from 'axios';

/**
  * Params.
  *
  *
  * @typedef {object} Params
  * @property {string} name
  * @property {string} zingfitName
  * @property {number} price
  * @property {string} description
  * @property {string} zingfitDescription
  * @property {string} appDisplayOrder
  * @property {string} appDisplayCategory
  * @property {boolean} enabled
  * @property {number} seriesTypeId
  * @property {boolean} exclude
  * @property {string} excludeDescription
  */

/**
  * Result.
  *
  * @typedef {object} Result
  * @property {string} id - The ID of the series.
  */

/**
  * Creates a series based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/series/create-series~Params} params - The parameters.
  * @returns {Promise<module:resources/series/create-series~Result>} - The promise.
  * @static
  */
const createSeries = (params) => {
  return axios.request({
    data: JSON.stringify(params.series),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/series',
  }).then((response) => {
    return response.data;
  });
};

export default createSeries;
