/**
 * Header.
 */
import { AppBar, Box, Button, Toolbar, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { styles } from 'css-common';
import { actions as authActions } from 'ducks/auth';

import oneRebel from './header-logo.png';

const Header = (_props) => {
  const { classes } = _props;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userAuthenticated = useSelector((store) => {
    return undefined !== store.auth.accessToken;
  }, shallowEqual);

  const signOut = () => {

    dispatch(authActions.signOut());
    navigate('/home');

  };

  return (
    <div>
      <header className='brt-header'>
        <AppBar className={ classes.appBar } position='static'>
          <Toolbar>
            <div style={ { flex: 1 } }>
              <Link className={ classes.link } to={ '/' }>
                <Box
                  alt='1Rebel'
                  component='img'
                  src={ oneRebel }
                  sx={
                    {
                      height: 30,
                      width: 90,
                    }
                  }
                />
              </Link>
            </div>
            <Link className={ classes.link } to={ '/ingestion' }>
              <Typography variant='h6'>
                 Ingestion
              </Typography>
            </Link>
            <Link className={ classes.link } to={ '/perks' }>
              <Typography variant='h6'>
                 Perks
              </Typography>
            </Link>
            <Link className={ classes.link } to={ '/quotes' }>
              <Typography variant='h6'>
                 Quotes
              </Typography>
            </Link>
            <Link className={ classes.link } to={ '/rooms' }>
              <Typography variant='h6'>
                 Rooms
              </Typography>
            </Link>
            <Link className={ classes.link } to={ '/series' }>
              <Typography variant='h6'>
                 Series
              </Typography>
            </Link>
            <Link className={ classes.link } to={ '/series-types' }>
              <Typography variant='h6'>
                 Series Types
              </Typography>
            </Link>
            <Link className={ classes.link } to={ '/spot-types' }>
              <Typography variant='h6'>
                 Spot Types
              </Typography>
            </Link>
            {
              userAuthenticated ? (
                <Button
                  color='secondary'
                  onClick={ signOut }
                  variant='contained'

                >
                  { 'Sign Out' }
                </Button>

              ):(
                <Button
                  color='primary'
                  onClick={
                    () => {
                      navigate('/sign-in');
                    }
                  }
                  variant='contained'
                >
                  { 'Sign In' }
                </Button>
              )
            }
          </Toolbar>
        </AppBar>
      </header>
    </div>
  );
};

Header.propTypes = {};

export default withStyles(styles)(Header);
