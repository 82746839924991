/**
 * Resource method to sign in.
 *
 * @module resources/auth/sign-in
 */
import axios from 'axios';

/**
  * Params.
  *
  * @typedef {object} Params
  * @property {string} email - The email.
  * @property {string} password - The password.
  */

/**
  * Result.
  *
  * @typedef {object} Result
  * @property {string} accessToken - The access token.
  */

/**
  * Signs in a user based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/auth/sign-in~Params} params - The parameters.
  * @returns {Promise<module:resources/auth/sign-in~Result>} - The promise.
  * @static
  */
const signIn = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/auth',
  }).then((response) => {
    return response.data;
  });
};

export default signIn;

