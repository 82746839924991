import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SimpleTable from 'components/common/table/simple-table';
import { actions as perksActions } from 'ducks/perks';
import { actions as requestsActions } from 'ducks/requests';
import * as perksMethods from 'resources/perks/index';
import * as toasts from 'toasts';

const PerkDelete = () => {

  // The error when deleting a perk.
  const [ error, setError ] = useState('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(perksMethods.getPerk, id, {
      onFailure: () => {
        toasts.info('Perk not found.');
      },
      onSuccess: (result) => {
        dispatch(perksActions.setPerk(result));
      },
    }));
  }, [ dispatch, id ]);

  const perkToDelete = useSelector((store) => {
    return store.perks.perk || {};
  }, shallowEqual);

  const columns = [
    {
      field: 'field',
      filterable: false,
      flex: 1,
    },
    {
      field: 'fieldValue',
      filterable: false,
      flex: 6,
      renderCell: (cellValues) => {
        return (
          <p style={
            { fontWeight: 'bolder',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word' }
          }
          >
            { cellValues.row.fieldValue }
          </p>);
      },
    },
  ];

  const deleteCurrentPerk = () => {

    setError('');

    dispatch(requestsActions.request(perksMethods.deletePerk, { id: id }, {
      onFailure: (_error) => {
        toasts.info('Perk could not be deleted.');
        setError(`Perk could not be deleted: ${ _error.name }`);
      },
      onSuccess: () => {
        dispatch(perksActions.deletePerk(id));
        toasts.info('Perk was deleted.');
        navigate('/perks');
      },
    }));
  };

  return (
    <div className='perk editor'>
      <h2 className='hdg hdg-md'>
        { 'Delete Perk?' }
      </h2>
      <SimpleTable
        columns={ columns }
        rows={
          [
            {
              field: 'Title :',
              fieldValue: perkToDelete.title,
            }, {
              field: 'Brand Name :',
              fieldValue: perkToDelete.brandName,
            }, {
              field: 'Brand image url :',
              fieldValue: perkToDelete.brandImageUrl,
            }, {
              field: 'Short description :',
              fieldValue: perkToDelete.shortDescription,
            }, {
              field: 'Description :',
              fieldValue: perkToDelete.description,
            }, {
              field: 'Redemption code :',
              fieldValue: perkToDelete.redemptionCode,
            }, {
              field: 'Redemption url :',
              fieldValue: perkToDelete.redemptionUrl,
            }, {
              field: 'Redemption QR code url :',
              fieldValue: perkToDelete.redemptionQrCodeUrl,
            }, {
              field: 'App display order :',
              fieldValue: perkToDelete.displayOrder,
            }, {
              field: 'Enabled: ',
              fieldValue: perkToDelete.enabled? 'yes':'no',
            },
          ]
        }
      />
      <div className='buttons'>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                navigate('/perks');
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='error'
            onClick={ deleteCurrentPerk }
            variant='contained'
          >
            { 'Delete Perk' }
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default PerkDelete;
