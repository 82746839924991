/**
 * Duck for perks.
 *
 * @module ducks/perks
 */

/**
 * The type of actions to describe that the perks have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const PERKS_SET = 'PERKS_SET';
const PERK_SET = 'PERK_SET';
const PERK_CREATE = 'PERK_CREATE';
const PERK_EDIT = 'PERK_EDIT';
const PERK_DELETE = 'PERK_DELETE';
const PERKS_SORT_TABLE = 'PERKS_SORT_TABLE';
const PERKS_FILTER_TABLE = 'PERKS_FILTER_TABLE';
const PERKS_PAGINATION_TABLE = 'PERKS_PAGINATION_TABLE';

/**
  *  Creates an action to set the perks to the given perks.
  *
  * @param {module:types/perks~Perk[]} perks - The perks.
  * @returns {object} - The action.
  * @static
  */

const setPerks = (perks) => {
  return {
    perks,
    type: PERKS_SET,
  };
};

const createPerk = (perk) => {
  return {
    perk,
    type: PERK_CREATE,
  };
};

const updatePerk = (perk) => {
  return {
    perk,
    type: PERK_EDIT,
  };
};

const deletePerk = (id) => {
  return {
    id,
    type: PERK_DELETE,
  };
};

const setPerk = (perk) => {
  return {
    perk,
    type: PERK_SET,
  };
};

const setPerksSortTable = (sortModel) => {
  return {
    sortModel,
    type: PERKS_SORT_TABLE,
  };
};

const setPerksFilterTable = (filterModel) => {
  return {
    filterModel,
    type: PERKS_FILTER_TABLE,
  };
};

const setPerksPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: PERKS_PAGINATION_TABLE,
  };
};

/**
  * Perks state.
  *
  * @typedef {object} State
  * @property {module:types/perks~Perk[]} perks - The different perks.
  * @property {module:types/perks~Perk} perkType - The current perk.
  * @property {module:types/perks~SortModel} sortModel - The sort model for the perks table
  * @property {module:types/perks~FilterModel} filterModel - The filter model for the perks table
  * @property {module:types/perks~PaginationModel} paginationModel - The pagination model for the perks table
  */

/**
   * The initial perks state.
   *
   * @type {module:ducks/perks~State}
   * @static
   */
const initialState = {
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  paginationModel: { page: 0 },
  perk: undefined,
  perks: [],
  sortModel: [ { field: 'brandName', sort: 'asc' } ],
};

/**
   * Applies the given action to the given state and returns the new state.
   *
   * @param {module:ducks/perks~State} state - The previous state.
   * @param {object} action - The action.
   * @returns {module:ducks/perks~State} - The new state.
   * @static
   */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case PERKS_SET:
    return {
      ...state,
      perk: undefined,
      perks: action.perks,
    };
  case PERK_SET:
    return {
      ...state,
      perk: action.perk,
    };
  case PERK_CREATE:
    return {
      ...state,
      perk: action.perk,
    };
  case PERK_EDIT:
    return {
      ...state,
      perk: action.perk,
    };
  case PERK_DELETE:
    return {
      ...state,
      perks: state.perks.filter((perk) => {
        return action.id !== perk.id.toString();
      }),
    };
  case PERKS_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case PERKS_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case PERKS_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  createPerk,
  deletePerk,
  setPerk,
  setPerks,
  setPerksFilterTable,
  setPerksPaginationTable,
  setPerksSortTable,
  updatePerk,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
