/**
 * Resource method to get perks.
 *
 * @module resources/perks/get-perks
 */
import axios from 'axios';

/**
 * Gets the perks.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/perks/get-perks~Result>} - The promise.
 * @static
 */
const getPerks = () => {
  return axios.request({
    method: 'get',
    url: '/perks/',
  }).then((response) => {
    return response.data;
  });
};

export default getPerks;
