/**
 * Resource method to get a single room.
 *
 * @module resources/rooms/get-room
 */
import axios from 'axios';

/**
  * Creates a room based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/rooms/get-room~Params} id - The id of the room to get.
  * @returns {Promise<module:resources/rooms/get-room~Result>} - The promise.
  * @static
  */
const getRoom = (id) => {
  return axios.request({
    method: 'get',
    url: `/rooms/${ id }`,
  }).then((response) => {
    return response.data;
  });
};

export default getRoom;
