/**
 * Resource method to get quotes.
 *
 * @module resources/quotes/get-quotes
 */
import axios from 'axios';

/**
 * Gets the quotes.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module/resources/quotes/get-quotes~Result>} - The promise.
 * @static
 */
const getQuotes = () => {
  return axios.request({
    method: 'get',
    url: '/quotes/',
  }).then((response) => {
    return response.data;
  });
};

export default getQuotes;
