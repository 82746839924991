/**
 * Resource method to update a room.
 *
 * @module resources/rooms/update-room
 */
import axios from 'axios';

import { omit } from 'utilities/chisels';

/**
  * Params.
  *
  *
  * @typedef {object} Params
  * @property {string} name
  * @property {string} layoutImageUrl
  * @property {string} siteId
  * @property {boolean} hidden
  */

/**
  * Result.
  *
  * @typedef {object} Result
  */

/**
  * Updates a room based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/rooms/update-room~Params} params - The parameters.
  * @returns {Promise<module:resources/rooms/update-room~Result>} - The promise.
  * @static
  */
const updateRoom = (params) => {
  return axios.request({
    data: JSON.stringify({
      ...omit(params.room, [ 'id' ]),
      part: params.part,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: `/rooms/${ params.room.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default updateRoom;
