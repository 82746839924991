/**
 * Footer.
 */
import React from 'react';

import { currentYear } from 'utilities/chisels';

import './footer.scss';

const Footer = (_props) => {
  return (
    <footer className='brt-footer'>
      © Copyright
      { ' ' }
      { currentYear() }
      { ' ' }
      -  One Rebel Ltd
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
