/**
 * Resource method to update a perk.
 *
 * @module resources/perks/update-perk
 */
import axios from 'axios';

import { omit } from 'utilities/chisels';

/**
   * Params.
   *
   *
   * @typedef {object} Params
   * @property {string} brandName
   * @property {string} brandImageUrl
   * @property {string} title
   * @property {string} shortDescription
   * @property {string} description
   * @property {string} redemptionUrl
   * @property {string} redemptionQrCodeUrl
   * @property {string} redemptionCode
   * @property {boolean} enabled
   * @property {string} displayOrder
   */

/**
   * Result.
   *
   * @typedef {object} Result
   */

/**
   * Updates a perk based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/perks/update-perk~Params} params - The parameters.
   * @returns {Promise<module:resources/perks/update-perk~Result>} - The promise.
   * @static
   */
const updatePerk = (params) => {
  return axios.request({
    data: JSON.stringify({
      ...omit(params.perk, [ 'id' ]),
      part: params.part,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: `/perks/${ params.perk.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default updatePerk;
