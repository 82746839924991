import React from 'react';
import { useNavigate } from 'react-router-dom';

import SeriesEditor from './series-editor/series-editor';

const SeriesCreate = () => {

  const navigate = useNavigate();

  return (
    <div>
      <h2>Series</h2>
      <SeriesEditor
        onCancel={
          () => {
            navigate('/series');
          }
        }
        onSave={
          () => {
            navigate('/Series');
          }
        }
        title='Create Series'
      />
    </div>
  );
};

export default SeriesCreate;
