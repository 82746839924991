import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions as perksActions } from 'ducks/perks';
import { actions as requestsActions } from 'ducks/requests';
import * as perksMethods from 'resources/perks/index';
import * as toasts from 'toasts';

import PerkEditor from './perk-editor/perk-editor';

const PerkEdit = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(perksMethods.getPerk, id, {
      onFailure: () => {
        toasts.info('Perk could not be loaded.');
      },
      onSuccess: (result) => {
        dispatch(perksActions.setPerk(result));
      },
    }));
  }, [ dispatch, id ]);

  const perkToEdit = useSelector((store) => {
    return store.perks.perk || {};
  }, shallowEqual);

  return (
    <div>
      <h2>Perks</h2>
      <PerkEditor
        onCancel={
          () => {
            navigate('/perks');
          }
        }
        onSave={
          () => {
            navigate('/perks');
          }
        }
        perk={ perkToEdit }
        title= { 'Edit Perk' }
      />
    </div>
  );
};

export default PerkEdit;
