/**
 * Store.
 *
 * @module store
 */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';

import * as auth from 'ducks/auth';
import * as ingestion from 'ducks/ingestion';
import * as perks from 'ducks/perks';
import * as quotes from 'ducks/quotes';
import * as requests from 'ducks/requests';
import * as rooms from 'ducks/rooms';
import * as series from 'ducks/series';
import * as seriesTypes from 'ducks/series-types';
import * as spotTypes from 'ducks/spot-types';
import * as toasts from 'ducks/toasts';
import { middleware as requestsMiddleware } from 'middlewares/requests';

/**
 * The app reducer.
 *
 * @type {function}
 * @static
 */
const appReducer = combineReducers({
  auth: auth.reducer,
  ingestion: ingestion.reducer,
  perks: perks.reducer,
  quotes: quotes.reducer,
  requests: requests.reducer,
  rooms: rooms.reducer,
  series: series.reducer,
  seriesTypes: seriesTypes.reducer,
  spotTypes: spotTypes.reducer,
  toasts: toasts.reducer,
});

const reducer = (state, action) => {
  if ('USER_SIGNED_OUT' === action.type) {
    storage.removeItem('persist:britney');
    sessionStorage.clear();
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

/**
 * The middlewares to apply.
 *
 * @type {function[]}
 * @static
 */
const middlewares = [
  // Thunks.
  thunkMiddleware,
  // Requests.
  requestsMiddleware,
];

/**
 * The enhancer.
 *
 * @type {function}
 * @static
 */
const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

/**
 * The store.
 *
 * @type {object}
 * @static
 */
const store = createStore(persistReducer({
  key: 'britney',
  storage,
}, reducer), enhancer);

const persistor = persistStore(store);

export {
  persistor,
  store,
};
