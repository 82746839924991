/**
  * Resource method to get rooms.
  *
  * @module resources/rooms/get-rooms
  */
import axios from 'axios';

/**
  * Gets the rooms.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @returns {Promise<module/resources/rooms/get-rooms~Result>} - The promise.
  * @static
  */
const getRooms = () => {
  return axios.request({
    method: 'get',
    url: '/rooms/',
  }).then((response) => {
    return response.data;
  });
};

export default getRooms;
