import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import DataTable from 'components/common/table/data-table';
import { actions as requestsActions } from 'ducks/requests';
import { actions as seriesActions } from 'ducks/series';
import { actions as seriesTypesActions } from 'ducks/series-types';
import * as seriesTypesMethods from 'resources/series-types/index';
import * as seriesMethods from 'resources/series/index';
import * as toasts from 'toasts';

const SeriesList = () => {

  const initialState = {
    filter: { filterModel: {
      items: [],
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    } },
    pagination: { page: 0 },
    sorting: [ { field: 'name', sort: 'asc' } ],
  };

  const navigate = useNavigate();

  const sortModel = useSelector((store) => {
    return store?.series?.sortModel || initialState.sorting;
  }, shallowEqual);

  const filterModel = useSelector((store) => {
    return store?.series?.filterModel || initialState.filter.filterModel;
  }, shallowEqual);

  const paginationModel = useSelector((store) => {
    return store?.series?.paginationModel || initialState.pagination;
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestsActions.request(seriesMethods.getAllSeries, null, {
      onFailure: (error) => {
        const message = 'Series could not be loaded.';
        if ('UnauthorizedError' === error.name) {
          toasts.info(`${ message } Are you signed in?`);
          navigate('/sign-in', { state: { from: '/series' } });
        } else {
          toasts.info(message);
        }
      },
      onSuccess: (result) => {
        dispatch(seriesActions.setAllSeries(result));
        dispatch(requestsActions.request(seriesTypesMethods.getSeriesTypes, null, {
          onFailure: () => {
            toasts.info('Series Types could not be loaded.');
          },
          onSuccess: (result_seriestypes) => {
            const map_result = result_seriestypes.reduce((map, obj) => {
              map[obj.id] = obj;
              return map;
            }, {});
            dispatch(seriesTypesActions.setSeriesTypesMap(map_result));
          },
        }));

      },
    }));
  }, [ dispatch, navigate ]);

  const allSeries = useSelector((store) => {
    return store.series.allSeries || [];
  }, shallowEqual);

  const allSeriesTypesMap = useSelector((store) => {
    return store.seriesTypes.seriesTypesMap || {};
  }, shallowEqual);

  const allSeriesWithSeriesTypes = allSeries?.map((elem) => {
    return { ...elem, seriesTypeName: (allSeriesTypesMap[elem.seriesTypeId])?.name };
  }) || [];

  const columns = [
    {
      field: 'edit_delete',
      filterable: false,
      headerName: 'Actions',
      renderCell: (cellValues) => {
        return (
          <>
            <div style={ { marginRight: '15px' } }>
              <Button
                color='danger'
                component={ Link }
                size='smaller'
                to={ `/series/edit/${ cellValues.row.id }` }
                variant='outlined'
              >
                Edit
              </Button>
            </div>
          </>
        );
      },
      sortable: false,
      width: 100,
    },
    {
      field: 'id',
      filterable: false,
      headerName: 'ID',
      minWidth: 180,
      sortable: false,
      type: 'number',
    },
    { field: 'name', headerName: 'Name', minWidth: 240, type: 'string' },
    { field: 'price', headerName: 'Price', minWidth: 100, type: 'number' },
    { field: 'description', headerName: 'Description', minWidth: 400, type: 'string' },
    {
      field: 'displayOrder',
      headerName: 'App Display Order',
      minWidth: 140,
      type: 'number',
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      type: 'boolean',
      width: 100,
    },
    { field: 'seriesTypeId', headerName: 'Series Type Id', minWidth: 120, type: 'number' },
    { field: 'seriesTypeName', headerName: 'Series Type Name', minWidth: 200, type: 'string' },
    {
      field: 'exclude',
      headerName: 'Exclude',
      type: 'boolean',
      width: 100,
    },
    { field: 'excludeDescription', headerName: 'Exclude Description', minWidth: 400, type: 'string' },
  ];

  const setSortModelToStore = (sortModel) => {
    dispatch(seriesActions.setSeriesSortTable(sortModel));
  };

  const setFilterModelToStore = (filterModel) => {
    dispatch(seriesActions.setSeriesFilterTable(filterModel));
  };

  const setPaginationModelToStore = (paginationModel) => {
    dispatch(seriesActions.setSeriesPaginationTable({ page: paginationModel }));
  };

  return (
    <div>
      <h2>Series</h2>
      <div className='ui celled list'>
        <DataTable
          columns={ columns }
          filterModel = { filterModel }
          onFilterListModel = { setFilterModelToStore }
          onPaginationModel = { setPaginationModelToStore }
          onSortListModel = { setSortModelToStore }
          pageSize={ 50 }
          paginationModel = { paginationModel }
          rows={ allSeriesWithSeriesTypes }
          rowsPerPageOptions={ [ 50 ] }
          sortModel={ sortModel }
        />
      </div>
    </div>
  );
};

export default SeriesList;
