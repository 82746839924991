import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SimpleTable from 'components/common/table/simple-table';
import { actions as requestsActions } from 'ducks/requests';
import { actions as spotTypesActions } from 'ducks/spot-types';
import * as spotTypesMethods from 'resources/spot-types/index';
import * as toasts from 'toasts';

const SpotTypeDelete = () => {

  // The error when deleting a spot type.
  const [ error, setError ] = useState('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(spotTypesMethods.getSpotType, id, {
      onFailure: () => {
        toasts.info('Spot Type not found.');
      },
      onSuccess: (result) => {
        dispatch(spotTypesActions.setSpotType(result));
      },
    }));
  }, [ dispatch, id ]);

  const spotTypeToDelete = useSelector((store) => {
    return store.spotTypes.spotType || {};
  }, shallowEqual);

  const columns = [
    {
      field: 'field',
      filterable: false,
      flex: 1,
    },
    {
      field: 'fieldValue',
      filterable: false,
      flex: 6,
      renderCell: (cellValues) => {
        return (
          <p style={
            { fontWeight: 'bolder',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word' }
          }
          >
            { cellValues.row.fieldValue }
          </p>);
      },
    },
  ];

  const deleteCurrentSpotType = () => {

    setError('');

    dispatch(requestsActions.request(spotTypesMethods.deleteSpotType, { id: id }, {
      onFailure: (_error) => {
        toasts.info('Spot Type could not be deleted.');
        setError(`Spot Type could not be deleted: ${ _error.name }`);
      },
      onSuccess: () => {
        dispatch(spotTypesActions.deleteSpotType(id));
        toasts.info('Spot Type was deleted.');
        navigate('/spot-types');
      },
    }));
  };

  return (
    <div className='spot-type editor'>
      <h2 className='hdg hdg-md'>
        { 'Delete Spot Type?' }
      </h2>
      <SimpleTable
        columns={ columns }
        rows={
          [
            {
              field: 'Name :',
              fieldValue: spotTypeToDelete.name,
            }, {
              field: 'Icon image url :',
              fieldValue: spotTypeToDelete.iconImageUrl,
            },
          ]
        }
      />
      <div className='buttons'>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                navigate('/spot-types');
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='error'
            onClick={ deleteCurrentSpotType }
            variant='contained'
          >
            { 'Delete Spot Type' }
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default SpotTypeDelete;
