/**
 * Utility functions.
 *
 * @module utilities/chisels
 */
import _ from 'lodash';
//import { DateTime } from 'luxon';

/**
 * Gets the current year.
 *
 * @returns {number} - The current year.
 * @static
 */
const currentYear = () => {
  const d = new Date();
  return d.getFullYear();
};

/**
 * Checks whether the given value is empty.
 *
 * @param {*} value - The value.
 * @returns {boolean} - Whether the value is empty.
 * @static
 */
const isEmpty = (value) => {
  return _.isEmpty(value);
};

/**
 * Checks whether the given value is not empty.
 *
 * @param {*} value - The value.
 * @returns {boolean} - Whether the value is not empty.
 * @static
 */
const isNotEmpty = (value) => {
  return !isEmpty(value);
};

/**
 * Returns a copy of the given object without the given paths.
 *
 * @param {object} o - The object.
 * @param {string[]} [paths] - The paths to omit.
 * @returns {boolean} - The new object.
 * @static
 */
const omit = (o, paths) => {
  return _.omit(o, paths);
};

export {
  currentYear,
  isEmpty,
  isNotEmpty,
  omit,
};
