/**
 * Resource method to delete a spot-type.
 *
 * @module resources/spot-types/delete-spot-type
 */
import axios from 'axios';

/**
   * Deletes a spot-type based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/spot-types/delete-spot-type~Id} id - The parameters.
   * @returns {Promise<module:resources/spot-types/delete-spot-type~Result>} - The promise.
   * @static
   */
const deleteSpotType = (id) => {
  return axios.request({
    data: id,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'delete',
    url: `/spot-types/${ id.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default deleteSpotType;
