/**
 * Duck for rooms.
 *
 * @module ducks/rooms
 */

/**
 * The type of actions to describe that the rooms have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const ROOMS_SET = 'ROOMS_SET';
const ROOM_SET = 'ROOM_SET';
const ROOM_EDIT = 'ROOM_EDIT';
const ROOM_DELETE = 'ROOM_DELETE';
const ROOMS_SORT_TABLE = 'ROOMS_SORT_TABLE';
const ROOMS_FILTER_TABLE = 'ROOMS_FILTER_TABLE';
const ROOMS_PAGINATION_TABLE = 'ROOMS_PAGINATION_TABLE';

/**
   *  Creates an action to set the rooms to the given rooms.
   *
   * @param {module:types/rooms~Room[]} rooms - The rooms.
   * @returns {object} - The action.
   * @static
   */

const setRooms = (rooms, sites) => {
  return {
    rooms: rooms,
    sites: sites,
    type: ROOMS_SET,
  };
};

const updateRoom = (room) => {
  return {
    room,
    type: ROOM_EDIT,
  };
};

const deleteRoom = (id) => {
  return {
    id,
    type: ROOM_DELETE,
  };
};

const setRoom = (room) => {
  return {
    room,
    type: ROOM_SET,
  };
};

const setRoomsSortTable = (sortModel) => {
  return {
    sortModel,
    type: ROOMS_SORT_TABLE,
  };
};

const setRoomsFilterTable = (filterModel) => {
  return {
    filterModel,
    type: ROOMS_FILTER_TABLE,
  };
};

const setRoomsPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: ROOMS_PAGINATION_TABLE,
  };
};

/**
   * Rooms state.
   *
   * @typedef {object} State
   * @property {module:types/rooms~Room[]} rooms - The different rooms.
   * @property {module:types/rooms~Room} room - The current room.
   * @property {module:types/rooms~SortModel} sortModel - The sort model for the rooms table
   * @property {module:types/rooms~FilterModel} filterModel - The filter model for the rooms table
   * @property {module:types/rooms~PaginationModel} paginationModel - The pagination model for the rooms table
   */

/**
    * The initial rooms state.
    *
    * @type {module:ducks/rooms~State}
    * @static
    */
const initialState = {
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  paginationModel: { page: 0 },
  room: undefined,
  rooms: [],
  sortModel: [ { field: 'siteName', sort: 'asc' } ],
};

/**
    * Applies the given action to the given state and returns the new state.
    *
    * @param {module:ducks/rooms~State} state - The previous state.
    * @param {object} action - The action.
    * @returns {module:ducks/rooms~State} - The new state.
    * @static
    */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ROOMS_SET:
    return {
      ...state,
      room: undefined,
      rooms: action.rooms.map((room) => {
        return {
          ...room,
          siteName: action.sites.find((site) => {
            return site.id === room.siteId;
          })?.name||room.siteId,
        };
      }),
    };
  case ROOM_SET:
    return {
      ...state,
      room: action.room,
    };
  case ROOM_EDIT:
    return {
      ...state,
      room: action.room,
    };
  case ROOM_DELETE:
    return {
      ...state,
      rooms: state.rooms.filter((room) => {
        return action.id !== room.id;
      }),
    };
  case ROOMS_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case ROOMS_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case ROOMS_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  deleteRoom,
  setRoom,
  setRooms,
  setRoomsFilterTable,
  setRoomsPaginationTable,
  setRoomsSortTable,
  updateRoom,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
