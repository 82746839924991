/**
 * Web vitals.
 *
 * @module helpers/web-vitals
 */
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

/**
 * Configures web vitals.
 *
 * @param {function} onReport - The callback to call any time a metric value is available and ready to be reported.
 * @returns {void}
 * @static
 */
const configure = (onReport) => {
  if (onReport && onReport instanceof Function) {
    getCLS(onReport);
    getFID(onReport);
    getFCP(onReport);
    getLCP(onReport);
    getTTFB(onReport);
  }
};

export default {
  configure,
};
