/**
 * Resource method to delete a quote.
 *
 * @module resources/quotes/delete-quote
 */
import axios from 'axios';

/**
   * Deletes a quote based on the given parameters.
   *
   * This function returns a promise that is either fulfilled with a result or rejected with an error.
   *
   * @param {module:resources/quotes/delete-quote~Id} id - The parameters.
   * @returns {Promise<module:resources/quotes/delete-quote~Result>} - The promise.
   * @static
   */
const deleteQuote = (id) => {
  return axios.request({
    data: id,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'delete',
    url: `/quotes/${ id.id }`,
  }).then((response) => {
    return response.data;
  });
};

export default deleteQuote;
