/**
 * Loader.
 */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import './loader.scss';

const Loader = (_props) => {
  // Whether there is an active request.
  const requestActive = useSelector((store) => {
    return store.requests.active;
  }, shallowEqual);

  return requestActive ? (
    <div className='brt-loader'>
      <div className='spinner' />
    </div>
  ) : null;
};

Loader.propTypes = {
};

Loader.defaultProps = {
};

export default Loader;
