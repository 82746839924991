/**
 * Resource method to create a series type.
 *
 * @module resources/series-types/create-series-type
 */
import axios from 'axios';

/**
  * Params.
  *
  * @typedef {object} Params
  * @property {string} name
  * @property {string} description
  * @property {boolean} eligibleForPerks
  * @property {string} displayOrder
  */

/**
  * Result.
  *
  * @typedef {object} Result
  * @property {string} id - The ID of the series type.
  */

/**
  * Creates a series type based on the given parameters.
  *
  * This function returns a promise that is either fulfilled with a result or rejected with an error.
  *
  * @param {module:resources/series-types/create-series-type~Params} params - The parameters.
  * @returns {Promise<module:resources/series-types/create-series-type~Result>} - The promise.
  * @static
  */
const createSeriesType = (params) => {
  return axios.request({
    data: JSON.stringify(params.seriesType),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/series-types',
  }).then((response) => {
    return response.data;
  });
};

export default createSeriesType;
