import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions as requestsActions } from 'ducks/requests';
import { actions as SeriesActions } from 'ducks/series';
import * as seriesMethods from 'resources/series/index';
import * as toasts from 'toasts';

import SeriesEditor from './series-editor/series-editor';

const SeriesEdit = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(requestsActions.request(seriesMethods.getSeries, id, {
      onFailure: () => {
        toasts.info('Series could not be loaded.');
      },
      onSuccess: (result) => {
        dispatch(SeriesActions.setSeries(result));
      },
    }));
  }, [ dispatch, id ]);

  const seriesToEdit = useSelector((store) => {
    return store.series.series || {};
  }, shallowEqual);

  return (
    <div>
      <h2>Series</h2>
      <SeriesEditor
        onCancel={
          () => {
            navigate('/series');
          }
        }
        onSave={
          () => {
            navigate('/series');
          }
        }
        series={ seriesToEdit }
        title= { 'Edit Series' }
      />
    </div>
  );
};

export default SeriesEdit;
