/**
 * Duck for series.
 *
 * @module ducks/series
 */

/**
 * The type of actions to describe that the series have been set.
 *
 * @constant {string}
 * @default
 * @static
 */
const SERIES_ALL_SET = 'SERIES_ALL_SET';
const SERIES_SET = 'SERIES_SET';
const SERIES_CREATE = 'SERIES_CREATE';
const SERIES_EDIT = 'SERIES_EDIT';
const SERIES_DELETE = 'SERIES_DELETE';
const SERIES_SORT_TABLE = 'SERIES_SORT_TABLE';
const SERIES_FILTER_TABLE = 'SERIES_FILTER_TABLE';
const SERIES_PAGINATION_TABLE = 'SERIES_PAGINATION_TABLE';

/**
  *  Creates an action to set the series to the given series.
  *
  * @param {module:types/series~Series[]} allSeries - The series.
  * @returns {object} - The action.
  * @static
  */

const setAllSeries = (allSeries) => {
  return {
    allSeries,
    type: SERIES_ALL_SET,
  };
};

const createSeries = (series) => {
  return {
    series,
    type: SERIES_CREATE,
  };
};

const updateSeries = (series) => {
  return {
    series,
    type: SERIES_EDIT,
  };
};

const deleteSeries = (id) => {
  return {
    id,
    type: SERIES_DELETE,
  };
};

const setSeries = (series) => {
  return {
    series,
    type: SERIES_SET,
  };
};

const setSeriesSortTable = (sortModel) => {
  return {
    sortModel,
    type: SERIES_SORT_TABLE,
  };
};

const setSeriesFilterTable = (filterModel) => {
  return {
    filterModel,
    type: SERIES_FILTER_TABLE,
  };
};

const setSeriesPaginationTable = (paginationModel) => {
  return {
    paginationModel,
    type: SERIES_PAGINATION_TABLE,
  };
};

/**
  * AllSeries state.
  *
  * @typedef {object} State
  * @property {module:types/series~Series[]} allSeries - The different series.
  * @property {module:types/series~Series} series - The current series.
  * @property {module:types/series~SortModel} sortModel - The sort model for the series table
  * @property {module:types/series~FilterModel} filterModel - The filter model for the series table
  * @property {module:types/series~PaginationModel} paginationModel - The pagination model for the series table
  */

/**
   * The initial series state.
   *
   * @type {module:ducks/series~State}
   * @static
   */
const initialState = {
  allSeries: [],
  filterModel: {
    items: [],
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
  paginationModel: { page: 0 },
  series: undefined,
  sortModel: [ { field: 'name', sort: 'asc' } ],
};
/**
   * Applies the given action to the given state and returns the new state.
   *
   * @param {module:ducks/series~State} state - The previous state.
   * @param {object} action - The action.
   * @returns {module:ducks/series~State} - The new state.
   * @static
   */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SERIES_ALL_SET:
    return {
      ...state,
      allSeries: action.allSeries,
      series: undefined,
    };
  case SERIES_SET:
    return {
      ...state,
      series: action.series,
    };
  case SERIES_CREATE:
    return {
      ...state,
      series: action.series,
    };
  case SERIES_EDIT:
    return {
      ...state,
      series: action.series,
    };
  case SERIES_DELETE:
    return {
      ...state,
      allSeries: state.allSeries.filter((series) => {
        return action.id !== series.id.toString();
      }),
    };
  case SERIES_SORT_TABLE:
    return {
      ...state,
      sortModel: action.sortModel,
    };
  case SERIES_FILTER_TABLE:
    return {
      ...state,
      filterModel: action.filterModel,
    };
  case SERIES_PAGINATION_TABLE:
    return {
      ...state,
      paginationModel: action.paginationModel,
    };
  default:
    return state;
  }
};

const actions = {
  createSeries,
  deleteSeries,
  setAllSeries,
  setSeries,
  setSeriesFilterTable,
  setSeriesPaginationTable,
  setSeriesSortTable,
  updateSeries,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
