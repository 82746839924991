/**
 * Series Type editor.
 */
import { joiResolver } from '@hookform/resolvers/joi';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actions as requestsActions } from 'ducks/requests';
import * as seriesTypesMethods from 'resources/series-types';
import * as toasts from 'toasts';

const SeriesTypeEditor = (props) => {

  // The error when saving changes.
  const [ error, setError ] = useState('');

  const dispatch = useDispatch();

  // The ID of the series type.
  const seriesTypeId = props?.seriesType?.id || null;

  const isAddMode = !seriesTypeId;

  // The validation schema.
  const schema = Joi.object({
    description: Joi.string().allow(''),
    displayOrder: Joi.number().allow(''),
    eligibleForPerks: Joi.bool().required(),
    name: Joi.string().min(1).required(),
  });

  const { control, formState: { errors }, handleSubmit, register, reset } = useForm({
    defaultValues: {
      description: '',
      displayOrder: '',
      eligibleForPerks: '',
      name: '',
    },
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const onFormSubmit = (params) => {
    return isAddMode
      ? createSeriesType(params)
      : updateSeriesType(seriesTypeId, params);
  };
  const createSeriesType = (params) => {
    setError('');

    dispatch(requestsActions.request(seriesTypesMethods.createSeriesType, {
      seriesType: {
        ...params,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('Series Type could not be created.');
        setError(`Series Type could not be created: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Series Type was created.');
        props.onSave?.(result);
      },
    }));
  };

  const updateSeriesType = (id, params) => {

    setError('');

    dispatch(requestsActions.request(seriesTypesMethods.updateSeriesType, {
      seriesType: {
        ...params,
        id: id,
      },
    }, {
      onFailure: (_error) => {
        toasts.info('seriesType could not be updated.');
        setError(`Series Type could not be updated: ${ _error.name }`);
      },
      onSuccess: (result) => {
        toasts.info('Series Type was updated.');
        props.onSave?.(result);
      },
    }));
  };

  // Fill in the form with values from the Series Type.
  useEffect(() => {
    const values = {};

    if (!isAddMode) {
      values.description = props?.seriesType?.description || '';
      values.displayOrder = props?.seriesType?.displayOrder || '';
      values.eligibleForPerks = props?.seriesType?.eligibleForPerks || false;
      values.name = props?.seriesType?.name || null;
    }
    reset(values);

  }, [ props.seriesType, reset, isAddMode ]);

  return (
    <div className='series-type editor'>
      <h2 className='hdg hdg-md'>{ props.title }</h2>
      <form autoComplete='off'>
        <Stack className='fields' >
          <TextField
            { ...register('name') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            disabled={ !isAddMode }
            error={ errors.name? true:false }
            helperText={ errors.name?.message }
            label={ 'Name' }
            margin='normal'
            name='name'
            placeholder={ 'Name' }
            required
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('description') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.description? true:false }
            helperText={ errors.description?.message }
            label={ 'Description' }
            margin='normal'
            minRows = { 5 }
            multiline
            name='description'
            placeholder={ 'Description' }
            size='small'
            variant = 'outlined'
          />
          <TextField
            { ...register('displayOrder') }
            InputLabelProps={ { shrink: true } }
            color='primary'
            error={ errors.displayOrder? true:false }
            helperText={ errors.displayOrder?.message }
            label={ 'App display order' }
            margin='normal'
            name='displayOrder'
            placeholder={ 'App display order' }
            size='small'
            variant = 'outlined'
          />
          <FormControl
            error={ errors.eligibleForPerks? true:false }
          >
            <FormLabel>Eligible for Perks</FormLabel>
            <Controller
              control={ control }
              name='eligibleForPerks'
              render={
                ({ field }) => {
                  return (
                    <RadioGroup
                      { ...field }
                      onChange={
                        (e) => {
                          field.onChange('true' === e.target.value);
                        }
                      }
                      row
                      value={ field?.value !== undefined ? field.value : '' }
                    >
                      <FormControlLabel
                        control={ <Radio /> }
                        label='Yes'
                        value={ 'true' }
                      />
                      <FormControlLabel
                        control={ <Radio /> }
                        label='No'
                        value={ 'false' }
                      />

                    </RadioGroup>
                  );
                }
              }
              rules={ { required: true } }
            />
            <FormHelperText>{ errors.eligibleForPerks?.message }</FormHelperText>
          </FormControl>

        </Stack>
        {
          undefined !== error && (
            <div className='error'>{ error }</div>
          )
        }
        <Box sx={ { mb: 5, mt: 3 } }>
          <Button
            color='neutral'
            onClick={
              () => {
                props.onCancel?.();
              }
            }
            sx={ { mr: 2 } }
            type='button'
            variant='outlined'
          >
            { 'Cancel' }
          </Button>
          <Button
            color='primary'
            onClick={ handleSubmit(onFormSubmit) }
            variant='contained'
          >
            { 'Save' }
          </Button>
        </Box>
      </form>
    </div>
  );
};

SeriesTypeEditor.propTypes = {
  // The function ((SeriesType) => void) to invoke when the user cancels the changes.
  onCancel: PropTypes.func.isRequired,
  // The function ((SeriesType) => void) to invoke when the user saves the changes.
  onSave: PropTypes.func.isRequired,
  // The seriesType
  seriesType: PropTypes.shape({
    description: PropTypes.string,
    displayOrder: PropTypes.number,
    eligibleForPerks: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default SeriesTypeEditor;
